/* eslint-disable max-lines */
import { Box, Button, Center, Group, Loader, Stack, TextInput, Tooltip } from '@mantine/core'
import { IconCalendar, IconCloudUpload } from '@tabler/icons-react'
import React from 'react'
import { promptCancelNewDoc, useDocDetailState } from '~/client/components/doc-detail/state'
import { DocTypesSelect } from '~/client/components/doc-detail/term/doc-types-select'
import { usePopulateFormWithAutofill } from '~/client/components/doc-detail/term/populate-form-with-autofill'
import { AutofillComp, type AutofillCompProps } from '~/client/components/util/autofill-comp'
import { DatePickerInputUTC } from '~/client/components/util/date-picker-utc'
import { zIndex } from '~/client/components/z-index'
import { docDetailButtonClass } from '~/client/lib/css-util.css'
import { hooks, useCurrentCorpAuth } from '~/client/lib/hooks'
import type { AutocheckValue } from '~/client/lib/hooks/auto-check'
import { theme } from '~/client/lib/theme'
import { AugmentedMetadataDate, docTypeStr } from '~/common/schema'
import { ZParty } from '~/common/schema/party'

const modifiedInputColor = theme.colors.blue[6]
const warningInputColor = theme.colors.urgent[6]

const getInputStyles = ({
  isDirty,
  autocheckResult,
}: {
  isDirty: boolean
  autocheckResult: AutocheckValue
}) => {
  if (autocheckResult === 'warning')
    return {
      input: { borderColor: warningInputColor },
    }
  if (isDirty)
    return {
      input: { borderColor: modifiedInputColor },
    }
  return {}
}

interface WithAutofillProps extends AutofillCompProps {
  hideIcon?: boolean
  isLoading?: boolean
}

const WithAutofill: React.FC<WithAutofillProps> = ({
  children,
  isLoading = false,
  hideIcon = false,
  valueIsFilled,
  autocheckResult,
  ...props
}) => {
  return (
    <Group align='flex-end' gap='xs' wrap='nowrap' justify='space-between'>
      <Box w='100%'>{children}</Box>
      {/* Hack to align icon with Input (because of extra space from title) */}
      <Center w={32} mb={4} style={hideIcon ? { opacity: 0, visibility: 'hidden' } : undefined}>
        {isLoading ? (
          <Loader size='xs' mb={4} />
        ) : (
          <AutofillComp
            valueIsFilled={valueIsFilled && autocheckResult === 'ok'}
            autocheckResult={autocheckResult}
            {...props}
          />
        )}
      </Center>
    </Group>
  )
}

const CounterParty: React.FC = () => {
  const { data: auth } = useCurrentCorpAuth()
  const form = useDocDetailState((state) => state.form)
  const isSaving = useDocDetailState((state) => state.isSaving)
  const _isLoading = useDocDetailState((state) => state.isLoading)
  const autofill = useDocDetailState((state) => state.autofill)
  const suggestionsLoadingStates = useDocDetailState((state) => state.suggestionsLoadingStates)
  const autocheckFieldResults = useDocDetailState((state) => state.autocheck.autocheckFieldResults)
  const hasName = !!form.values.party.name
  const hasEmail = !!form.values.party.email
  const isLoading = _isLoading || isSaving
  const isInvestor = auth?.level === 'investor'

  return (
    <>
      <WithAutofill
        isLoading={suggestionsLoadingStates.parties}
        targetInputDisabled={isLoading}
        autofills={autofill?.parties.map((item) => ZParty.display(item))}
        onAutofill={(index) => {
          const party = autofill?.parties[index]
          form.setFieldValue('party.name', party?.name)
          if (!hasEmail) form.setFieldValue('party.email', party?.email)
        }}
        valueIsFilled={hasName}
        autocheckResult={autocheckFieldResults.parties}
      >
        <TextInput
          disabled={isLoading}
          readOnly={isInvestor}
          type='text'
          label='Counterparty'
          data-testid='counterparty-input'
          {...form.getInputProps('party.name')}
          styles={getInputStyles({
            isDirty: form.isDirty('party.name'),
            autocheckResult: autocheckFieldResults.parties,
          })}
        />
      </WithAutofill>
      <WithAutofill
        testid='email-autofill'
        isLoading={suggestionsLoadingStates.emails}
        targetInputDisabled={isLoading}
        autofills={autofill?.emails}
        onAutofill={(index) => {
          const email = autofill?.emails[index]
          form.setFieldValue('party.email', email)
        }}
        valueIsFilled={hasEmail}
        autocheckResult={autocheckFieldResults.emails}
      >
        <TextInput
          disabled={isLoading}
          readOnly={isInvestor}
          type='email'
          label='Counterparty Email'
          data-testid='counterparty-email-input'
          {...form.getInputProps('party.email')}
          styles={getInputStyles({
            isDirty: form.isDirty('party.email'),
            autocheckResult: autocheckFieldResults.emails,
          })}
        />
      </WithAutofill>
    </>
  )
}

const Properties: React.FC = () => {
  const { data: auth } = useCurrentCorpAuth()
  const form = useDocDetailState((state) => state.form)
  const allowedTypes = useDocDetailState((state) => state.allowedTypes)
  const isSaving = useDocDetailState((state) => state.isSaving)
  const allowAllTypes = useDocDetailState((state) => state.allowAllTypes)
  const _isLoading = useDocDetailState((state) => state.isLoading)
  const autofill = useDocDetailState((state) => state.autofill)
  const suggestionsLoadingStates = useDocDetailState((state) => state.suggestionsLoadingStates)
  const isNewDoc = useDocDetailState((state) => state.isNewDoc)
  const preselectedType = useDocDetailState((state) => state.preselectedType)
  const autocheckFieldResults = useDocDetailState((state) => state.autocheck.autocheckFieldResults)
  const [filledType, setFilledType] = React.useState(false)
  const isInvestor = auth?.level === 'investor'
  const isLoading = _isLoading || isSaving

  return (
    <Tooltip.Floating disabled={!isSaving} label='Saving...' withinPortal={false}>
      <Stack gap='xs'>
        <WithAutofill
          testid='title-autofill'
          isLoading={suggestionsLoadingStates.titles}
          targetInputDisabled={isLoading}
          autofills={autofill?.titles}
          onAutofill={(index) => {
            form.setFieldValue('title', autofill?.titles[index])
          }}
          valueIsFilled={!!form.values.title}
          autocheckResult={autocheckFieldResults.titles}
        >
          <TextInput
            label='Document Title'
            data-testid='contract-title-input'
            disabled={isLoading}
            readOnly={isInvestor}
            styles={getInputStyles({
              isDirty: form.isDirty('title'),
              autocheckResult: autocheckFieldResults.titles,
            })}
            {...form.getInputProps('title')}
          />
        </WithAutofill>
        <WithAutofill
          testid='type-autofill'
          isLoading={suggestionsLoadingStates.types}
          targetInputDisabled={isLoading}
          autofills={autofill?.types.map((item) => docTypeStr(item))}
          onAutofill={(index) => {
            const typeValue = autofill?.types[index]
            if (typeValue) {
              form.setFieldValue('type', typeValue)
              setFilledType(true)
            }
          }}
          valueIsFilled={
            // Show if the doc is on /processing or being uploaded to a relation/corp
            !(form.values.type === 'PROCESSING' || isNewDoc) ||
            // Do not show id there is only 1 type and it's already selected
            (autofill?.types.length === 1 && form.values.type === autofill.types[0]) ||
            // do not show when a type is pre-selected (e.g. missing doc upload)
            !!preselectedType ||
            filledType
          }
          autocheckResult={autocheckFieldResults.types}
        >
          <DocTypesSelect
            label='Type'
            placeholder='None selected'
            disabled={isLoading}
            readOnly={isInvestor}
            required
            {...form.getInputProps('type')}
            data-testid='contract-type-select'
            allowedTypes={allowedTypes}
            allowAllTypes={allowAllTypes}
            styles={getInputStyles({
              isDirty: form.isDirty('type'),
              autocheckResult: autocheckFieldResults.types,
            })}
          />
        </WithAutofill>
        <CounterParty />
        <WithAutofill
          testid='startDate-autofill'
          isLoading={suggestionsLoadingStates.dates}
          targetInputDisabled={isLoading}
          autofills={autofill?.startDates.map((item) => AugmentedMetadataDate.display(item) ?? '')}
          onAutofill={(index) => {
            // we cannot set the value to `null` so we set it to `undefined` in that case
            form.setFieldValue('startDate', autofill?.startDates[index] ?? undefined)
          }}
          valueIsFilled={!!form.values.startDate}
          autocheckResult={autocheckFieldResults.startDates}
        >
          <DatePickerInputUTC
            data-testid='doc-detail-startDate-input'
            label='Date'
            readOnly={isInvestor}
            firstDayOfWeek={0}
            allowDeselect={false}
            leftSection={<IconCalendar size={16} />}
            popoverProps={{ zIndex: zIndex.modal }}
            disabled={isLoading}
            {...form.getInputProps('startDate')}
            styles={getInputStyles({
              isDirty: form.isDirty('startDate'),
              autocheckResult: autocheckFieldResults.startDates,
            })}
          />
        </WithAutofill>
      </Stack>
    </Tooltip.Floating>
  )
}

export const PropertiesTab: React.FC<{ onCancel?: () => void }> = ({ onCancel }) => {
  const { data: auth } = useCurrentCorpAuth()
  const isInvestor = auth?.level === 'investor'
  const form = useDocDetailState((state) => state.form)
  const isNewDoc = useDocDetailState((state) => state.isNewDoc)
  const cryptId = useDocDetailState((state) => state.docCryptId)
  const isSaving = useDocDetailState((state) => state.isSaving)
  const autocheckAllOk = useDocDetailState((state) => state.autocheck.autocheckAllOk)

  const deleteDoc = hooks.trpc().doc.delete.useMutationWithCorp()

  usePopulateFormWithAutofill()

  const saveButtonDisabled = !form.isDirty() || isInvestor
  // Shows Delete when creating a doc and Cancel when editing one
  const showDeleteButton = isNewDoc

  return (
    <>
      <Properties />
      <Group my='md' gap='lg' justify='flex-end'>
        {onCancel &&
          (showDeleteButton ? (
            <Button
              color='gray'
              variant='subtle'
              onClick={async () => {
                const hasConfirmed = await promptCancelNewDoc()
                if (cryptId && hasConfirmed) {
                  deleteDoc.mutate({ cryptId })
                  // Prevent a second confirmation to discard unsaved changes
                  form.resetDirty()
                  onCancel()
                }
              }}
              disabled={isSaving}
              data-testid='delete-doc-detail'
            >
              Delete
            </Button>
          ) : (
            <Button
              color='gray'
              variant='subtle'
              onClick={onCancel}
              disabled={isSaving}
              data-testid='cancel-doc-detail'
            >
              Cancel
            </Button>
          ))}
        <Tooltip
          label='Your changes have been saved!'
          events={{ hover: isInvestor ? false : saveButtonDisabled, focus: false, touch: false }}
          data-testid='save-doc-detail-tooltip-success'
        >
          <Button
            color={autocheckAllOk ? 'primary' : warningInputColor}
            type='submit'
            loading={isSaving}
            leftSection={<IconCloudUpload />}
            data-testid='save-doc-detail'
            // HACK so tooltip is only shown when button is disabled
            // https://github.com/mantinedev/mantine/issues/2959#issuecomment-1314064763
            {...(saveButtonDisabled ? { 'data-disabled': true } : undefined)}
            className={docDetailButtonClass}
            onClick={saveButtonDisabled ? (e) => e.preventDefault() : undefined}
          >
            Save
          </Button>
        </Tooltip>
      </Group>
    </>
  )
}

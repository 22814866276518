import { Overlay, ScrollArea } from '@mantine/core'
import dynamic from 'next/dynamic'
import React from 'react'
import { AerialAppShell } from '~/client/components/layout/aerial-app-shell'
import { AppNavbar } from '~/client/components/layout/navbar'
import { useLayoutStore } from '~/client/components/layout/state'
import { NotFound } from '~/client/components/not-found'
import { LoginRequired } from '~/client/components/protections'
import { CorpProvider } from '~/client/components/provider'
import { AppBreadcrumbs } from '~/client/components/util'
import { PageTitle } from '~/client/components/util/page-title'
import { zIndex } from '~/client/components/z-index'
import { useCurrentCorpAuth, useResetOnboardingOnCorpChange } from '~/client/lib/hooks'
import { useDeviceByWidth } from '~/client/lib/hooks/responsive'
import { useCurrentTopPathFromUrl, useRouteDataFromTopPath } from '~/client/lib/hooks/route-data'
import { useBreadcrumbs } from '~/client/lib/hooks/use-breadcrumbs'
import { AppHeader } from './header'

// use dynamic imports to reduce initial bundle size
const MultiDocDropModal = dynamic(() =>
  import('~/client/components/multi-doc-drop').then((module) => module.MultiDocDropModal)
)

const DocDetailModal = dynamic(() =>
  import('~/client/components/doc-detail').then((module) => module.DocDetailModal)
)

interface CorpPageProps {
  title?: string
  noScroll?: boolean
  noModals?: boolean
}

const WithPageTitleAndBreadcrumbs: React.FC<Pick<CorpPageProps, 'title'>> = ({
  title,
  children,
}) => {
  const breadcrumbs = useBreadcrumbs()

  const currentTopPath = useCurrentTopPathFromUrl()
  const routeData = useRouteDataFromTopPath(currentTopPath)

  const pageTitle = React.useMemo(() => {
    if (title) return title
    if (!routeData) return undefined
    if ('display' in routeData) return routeData.display
    return routeData.name
  }, [routeData, title])

  return (
    <>
      {pageTitle && <PageTitle title={pageTitle} />}
      {breadcrumbs && <AppBreadcrumbs items={breadcrumbs} />}
      {children}
    </>
  )
}

const CorpApp: React.FC<CorpPageProps> = ({ children, noScroll, title, noModals }) => {
  useResetOnboardingOnCorpChange()
  const { error } = useCurrentCorpAuth()
  const { device } = useDeviceByWidth()
  const { isNavbarOpen } = useLayoutStore()

  if (error) {
    return (
      <AerialAppShell header={<AppHeader noCorpUrl />}>
        <NotFound type='corporation' />
      </AerialAppShell>
    )
  }

  return (
    <AerialAppShell header={<AppHeader />} nav={<AppNavbar />}>
      <Overlay
        zIndex={zIndex.mobileOverlay}
        color='black'
        backgroundOpacity={0.7}
        display={device === 'desktop' || !isNavbarOpen ? 'none' : 'block'}
        pos='fixed'
      />
      {noScroll ? (
        <WithPageTitleAndBreadcrumbs title={title}>{children}</WithPageTitleAndBreadcrumbs>
      ) : (
        <ScrollArea h='100%'>
          <WithPageTitleAndBreadcrumbs title={title}>{children}</WithPageTitleAndBreadcrumbs>
        </ScrollArea>
      )}
      {!noModals && (
        <>
          <MultiDocDropModal />
          <DocDetailModal />
        </>
      )}
    </AerialAppShell>
  )
}

export const CorpLayout: React.FC<CorpPageProps> = (props) => (
  <LoginRequired>
    <CorpProvider>
      <CorpApp {...props} />
    </CorpProvider>
  </LoginRequired>
)

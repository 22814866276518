import type { ZDocType } from '~/common/schema'
import { RouteType } from './base'

export class _DocViewRoute<Collection extends string> {
  readonly routeType = RouteType.DOCUMENT
  collection: Collection
  name: string
  docTypes: ZDocType[]

  constructor(collection: Collection, name: string, docTypes: ZDocType[]) {
    this.collection = collection
    this.name = name
    this.docTypes = docTypes
  }
}

export const docViewRouteData = [
  new _DocViewRoute('customer', 'Customer', ['CUSTOMER_AGREEMENT']),
  new _DocViewRoute('contractor', 'Contractor', ['CONTRACTOR_AGREEMENT']),
  new _DocViewRoute('non-disclosure', 'Non-Disclosure', ['NON_DISCLOSURE_AGREEMENT']),
  new _DocViewRoute('joint-venture', 'Joint Venture', ['JOINT_VENTURES_AGREEMENT']),
  new _DocViewRoute('loi-mou', 'LOI/MOU', ['LETTER_OF_INTENT', 'MEMORANDUM_OF_UNDERSTANDING']),
  new _DocViewRoute('patent', 'Patent', ['PATENT']),
  new _DocViewRoute('copyright', 'Copyright', ['COPYRIGHT']),
  new _DocViewRoute('ip-agreements', 'IP Agreements', [
    'PROPRIETARY_INFORMATION_AND_INVENTION_ASSIGNMENT',
    'IP_ASSIGNMENT',
    'IP_LICENSE',
  ]),
  new _DocViewRoute('trademark', 'Trademark', ['TRADEMARK']),
  new _DocViewRoute('financials', 'Financials', [
    'AUDITOR_LETTER',
    'INCOME_STATEMENT',
    'BALANCE_SHEET',
    'OTHER_FINANCIAL_STATEMENTS',
  ]),
  new _DocViewRoute('tax', 'Tax', ['TAX_FILING']),
  new _DocViewRoute('debt', 'Debt', ['DEBT']),
  new _DocViewRoute('insurance', 'Insurance', [
    'CERTIFICATE_OF_INSURANCE',
    'INSURANCE_AGREEMENT',
    'OTHER_INSURANCE_DOCUMENT',
  ]),
  new _DocViewRoute('board-consents', 'Board Consents', ['BOARD_CONSENT_AND_MINUTES']),
  new _DocViewRoute('stockholder-consents', 'Stockholder Consents', ['STOCKHOLDER_CONSENT']),
  new _DocViewRoute('real-estate', 'Real Estate', ['REAL_ESTATE_LEASE', 'PURCHASED_REAL_ESTATE']),
  new _DocViewRoute('indemnification-agreements', 'Indemnification', ['INDEMNIFICATION_AGREEMENT']),
  new _DocViewRoute('miscellaneous', 'Misc. Legal Documents', ['MISCELLANEOUS']),
  new _DocViewRoute('benefit-plan', 'Benefit Plan', [
    'COMMUTER_BENEFIT_AGREEMENT',
    'HEALTH_INSURANCE_AGREEMENT',
    'DENTAL_PLAN_AGREEMENT',
    'VISION_PLAN_AGREEMENT',
    '_401K_PLAN',
    'EMPLOYEE_HANDBOOK',
  ]),
  new _DocViewRoute('uncategorized', 'Uncategorized', ['UNCATEGORIZED']),
  new _DocViewRoute('non-legal', 'Non-legal', ['NON_LEGAL']),
]

export type DocViewPaths = (typeof docViewRouteData)[number]['collection']

export type DocViewRoute = (typeof docViewRouteData)[number]

// eslint-disable-next-line custom-rules/prefer-map-to-object-from-entries, custom-rules/no-bad-casting-in-declaration
export const docViewRouteMap = Object.fromEntries(
  docViewRouteData.map((route) => [route.collection, route])
) as Record<DocViewPaths, DocViewRoute>

export type { QuickAnswerValuePrefix } from './quick-answer-search'
export { extractQuickAnswersValuePrefix } from './quick-answer-search'
export { useCorpSearch } from './corp-search'
export {
  mkDocsAndCount,
  useDocSearch,
  useDocSearchState,
  type DocSearchResults,
} from './doc-search'
export {
  isQuickAnswerOptionValue,
  useQuickAnswerSearchOptions,
  useQuickAnswerSearch,
  type QuickAnswerData,
  type QuickAnswersRtn,
} from './quick-answer-search'
export {
  type RelationsSearchParams,
  mkRelationsAndCount,
  useRelationSearch,
} from './relation-search'
export {
  useSearchOptions,
  type UseSearchOptions,
  stringComparisonOptionsFilterFn,
  type ItemWithSearch,
  isTypeOption,
  mkTypeOptionValue,
} from './util'

import type { LoaderProps, ThemeIconProps } from '@mantine/core'
import {
  Button,
  Center,
  CopyButton,
  Group,
  HoverCard,
  Loader,
  Stack,
  Table,
  Text,
  ThemeIcon,
  Title,
  Tooltip,
  DEFAULT_THEME as _theme,
} from '@mantine/core'
import { IconAlertOctagon, IconCheck, IconCopy } from '@tabler/icons-react'
import type { UseQueryResult } from '@tanstack/react-query'
import React from 'react'
import { zIndex } from '~/client/components/z-index'
import { theme } from '~/client/lib/theme'
import { getErrorMessage } from '~/common/util'
import { FullScreenMsg } from '.'

export const getCopyButton = (errorDetailsDisplay: string | null): React.ReactNode => {
  if (!errorDetailsDisplay) return null

  return (
    <CopyButton value={errorDetailsDisplay} timeout={2000}>
      {({ copied, copy }) => (
        <Button variant='subtle' onClick={copy}>
          <Group style={{ gap: _theme.spacing.xs }}>
            <span>Copy Details</span>
            <ThemeIcon color={copied ? 'go' : 'primary'}>
              {copied ? <IconCheck /> : <IconCopy />}
            </ThemeIcon>
          </Group>
        </Button>
      )}
    </CopyButton>
  )
}

// Shows sentry ID if it exists and does not show JSON to user
const getErrorCopy = (error: unknown): string | null => {
  if (error instanceof Object && 'sentryEventId' in error)
    return `Sentry Error ID: ${error.sentryEventId}`
  return null
}

interface ErrorDisplayCompProps {
  error: unknown
  /** How the error is displayed to the user. `fullscreen` adds a fixed margin
   * to the element, while `fill-auto-my` makes the margin 'auto' (more suitable
   * for small cards).
   *
   * The tooltip variant is a small icon that displays the error message on
   * hover, which should be used when the error message is not critical.
   *   */
  variant?: 'fullscreen' | 'fill-auto-my' | 'tooltip'
  errorIconSize?: ThemeIconProps['size']
  retry?: () => Promise<unknown>
}
export const ErrorDisplayComp: React.FC<ErrorDisplayCompProps> = ({
  error,
  variant = 'fullscreen',
  retry,
  errorIconSize,
}) => {
  const message = getErrorMessage(error, true)
  const sentryIdCopy = getErrorCopy(error)

  const buttons = (
    <Group style={{ gap: theme.spacing.xs }}>
      {getCopyButton(sentryIdCopy)}
      {retry && (
        <Button variant='subtle' onClick={retry}>
          Retry
        </Button>
      )}
    </Group>
  )

  if (variant === 'tooltip')
    return (
      <Center>
        <HoverCard position='bottom' shadow='md' zIndex={zIndex.popover} withArrow>
          <HoverCard.Target>
            <ThemeIcon color='danger' size={errorIconSize}>
              <IconAlertOctagon />
            </ThemeIcon>
          </HoverCard.Target>
          <HoverCard.Dropdown>
            <Group wrap='nowrap'>
              <ThemeIcon color='danger' size='lg'>
                <IconAlertOctagon />
              </ThemeIcon>
              <Stack gap='xs'>
                <Title order={3} c='danger'>
                  Error
                </Title>
                <Text c='dimmed' size='sm'>
                  {message}
                </Text>
                {buttons}
              </Stack>
            </Group>
          </HoverCard.Dropdown>
        </HoverCard>
      </Center>
    )

  return (
    <FullScreenMsg
      title={message}
      icon={
        <ThemeIcon color='danger'>
          <IconAlertOctagon />
        </ThemeIcon>
      }
      my={variant === 'fill-auto-my' ? 'auto' : 128}
    >
      {buttons}
    </FullScreenMsg>
  )
}

export const LoadingComp: React.FC<{
  variant?: 'fullscreen' | 'tooltip'
  size?: LoaderProps['size']
}> = ({ variant, size = 'md' }) => {
  if (variant === 'tooltip')
    return (
      <Tooltip label='Loading Data ...'>
        <Center>
          <Loader size={size} />
        </Center>
      </Tooltip>
    )

  return (
    <div data-testid='fullscreen-loading-comp'>
      <FullScreenMsg title='Loading Data ...'>
        <Loader size={size} />
      </FullScreenMsg>
    </div>
  )
}

const OptionalFullTableWidth: React.FC<{ fullTableWidth?: boolean }> = ({
  children,
  fullTableWidth,
}) => {
  if (fullTableWidth) return <Table.Td colSpan={99}>{children}</Table.Td>
  return <>{children}</>
}

interface ErrorQuery extends Pick<UseQueryResult, 'isError' | 'error'> {
  refetch?: () => Promise<unknown>
}

/**
 * A component that renders an error message based on the query result.
 */
export const ErrorComp: React.FC<{
  queryResult: ErrorQuery
  fullTableWidth?: boolean
  variant?: ErrorDisplayCompProps['variant']
  errorIconSize?: ErrorDisplayCompProps['errorIconSize']
}> = ({ queryResult, variant = 'fullscreen', fullTableWidth, children, errorIconSize }) => {
  if (queryResult.isError)
    return (
      <OptionalFullTableWidth fullTableWidth={fullTableWidth}>
        <ErrorDisplayComp
          error={queryResult.error}
          variant={variant}
          retry={queryResult.refetch}
          errorIconSize={errorIconSize}
        />
      </OptionalFullTableWidth>
    )

  return <>{children}</>
}

interface LoadingQuery extends Pick<UseQueryResult, 'isLoading' | 'isError' | 'error'> {
  refetch?: () => Promise<unknown>
}

/**
 * A component that renders a loading spinner or error message based on the query result.
 * If a skeleton is provided, it will be rendered instead of the spinner
 */
export const LoadingErrorComp: React.FC<{
  queryResult: LoadingQuery
  variant?: 'fullscreen' | 'tooltip'
  loaderSize?: LoaderProps['size']
  skeleton?: JSX.Element
  fullTableWidth?: boolean
  errorIconSize?: ThemeIconProps['size']
}> = ({
  queryResult,
  variant = 'fullscreen',
  loaderSize = 'md',
  skeleton,
  fullTableWidth,
  errorIconSize,
  children,
}) => {
  if (queryResult.isLoading && skeleton) return <>{skeleton}</>
  if (queryResult.isLoading)
    return (
      <OptionalFullTableWidth fullTableWidth={fullTableWidth}>
        <LoadingComp variant={variant} size={loaderSize} />
      </OptionalFullTableWidth>
    )

  return (
    <ErrorComp
      queryResult={queryResult}
      fullTableWidth={fullTableWidth}
      variant={variant}
      errorIconSize={errorIconSize}
    >
      {children}
    </ErrorComp>
  )
}

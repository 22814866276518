import { useDebouncedState as useDebouncedValue } from '@mantine/hooks'
import type {
  FetchNextPageOptions,
  InfiniteData,
  RefetchOptions,
  UseInfiniteQueryResult,
} from '@tanstack/react-query'
import * as React from 'react'
import { hooks } from '~/client/lib/hooks'
import { nextPageParamOpts } from '~/client/lib/hooks/query'
import { splitTypeAndTextQueries } from '~/client/lib/hooks/search/util'
import type { ZNumberExceed } from '~/common/number-exceed'
import type { Paginated, ZAugmentedDocWithHighlights, ZDocType } from '~/common/schema'
import { ZAugmentedDoc } from '~/common/schema'
import { zenvCommon } from '~/common/zenv-common'

/**
 * Debounced search queries.  Manages debounced state
 * @param queries from URL
 * @returns
 */
export const useDocSearchState = (
  queries: string[] = []
): [string[], (value: string[]) => void] => {
  const [debouncedValue, setDebouncedValue] = useDebouncedValue<string[]>(queries, 500)
  React.useEffect(() => {
    setDebouncedValue(queries)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [queries.toString()])

  return [debouncedValue, setDebouncedValue]
}

interface DocSearchData extends InfiniteData<Paginated<ZAugmentedDoc>> {
  count: ZNumberExceed
}

export interface DocSearchResults
  extends Pick<
    UseInfiniteQueryResult<Paginated<ZAugmentedDocWithHighlights>>,
    | 'error'
    | 'isError'
    | 'hasNextPage'
    | 'isFetching'
    | 'isFetchingNextPage'
    | 'isLoading'
    | 'refetch'
    | 'fetchNextPage'
  > {
  data: DocSearchData | undefined
}

interface UseDocSearch {
  noQuery: boolean
  docTypes: ZDocType[]
  setQuery: (value: string[]) => Promise<void>
  searchResults: DocSearchResults
}

export const useDocSearch = (queries: string[] = []): UseDocSearch => {
  const [debouncedValue, setDebouncedValue] = useDocSearchState(queries)

  const { texts, types } = splitTypeAndTextQueries(debouncedValue)
  const docTypes = types.filter(ZAugmentedDoc.isType)

  const searchResults = hooks.trpc().docs.search.useInfiniteQueryWithCorp(
    {
      types: docTypes,
      query: texts,
      limit: 10,
      sortField: 'score',
      direction: -1, // most recently created documents first
    },
    {
      keepPreviousData: true,
      ...nextPageParamOpts(),
    }
  )

  const excludeCryptIds =
    searchResults.data?.pages.flatMap((page) => page.data.map((doc) => doc.cryptId)) ?? []

  const fullTextSearchEnabled = !searchResults.isLoading && !searchResults.hasNextPage

  const fullTextSearchResults = hooks.trpc().docs.fullTextSearch.useInfiniteQueryWithCorp(
    { queries: texts, limit: 10, excludeCryptIds },
    {
      enabled: fullTextSearchEnabled,
      keepPreviousData: true,
      ...nextPageParamOpts(),
    }
  )

  const trpcContext = hooks.trpc().useContext()
  const setQuery = React.useCallback(
    async (v: string[]) => {
      setDebouncedValue(v)
      await trpcContext.docs.search.cancel()
    },
    [setDebouncedValue, trpcContext.docs.search]
  )

  const defaultCount = { count: 0, exceeds: false }
  const searchResultsCount = searchResults.data?.pages[0]?.count ?? defaultCount
  const fullTextSearchResultsCount = fullTextSearchResults.data?.pages[0]?.count ?? defaultCount

  const refetch = React.useCallback(
    (options?: RefetchOptions | undefined) => {
      if (
        fullTextSearchResults.isError ||
        // check if we are currently reading results from the full text search
        (!searchResults.hasNextPage && fullTextSearchResultsCount.count > 0)
      ) {
        return fullTextSearchResults.refetch(options)
      }
      return searchResults.refetch(options)
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [
      searchResults.hasNextPage,
      searchResults.refetch,
      fullTextSearchResults.isError,
      fullTextSearchResults.refetch,
      fullTextSearchResultsCount.count,
    ]
  )

  const fetchNextPage = React.useCallback(
    (options?: FetchNextPageOptions) => {
      if (searchResults.hasNextPage) {
        return searchResults.fetchNextPage(options)
      }
      return fullTextSearchResults.fetchNextPage(options)
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [searchResults.hasNextPage, fullTextSearchResults.fetchNextPage, searchResults.fetchNextPage]
  )

  const data = React.useMemo(() => {
    // ensures the first query is not loading
    if (!searchResults.data) return

    const pages = [
      ...(searchResultsCount.count > 0 ? searchResults.data.pages : []),
      ...(!searchResults.hasNextPage ? fullTextSearchResults.data?.pages ?? [] : []),
    ]

    const getCount = () => {
      // show vector search results count if it's the current active query. We
      // set exceeds to true here because full text search might have documents too.
      if (searchResults.hasNextPage || fullTextSearchResults.isLoading)
        return { ...searchResultsCount, exceeds: true }

      const totalCount = searchResultsCount.count + fullTextSearchResultsCount.count
      const exceeds =
        searchResultsCount.exceeds ||
        fullTextSearchResultsCount.exceeds ||
        totalCount > zenvCommon.NEXT_PUBLIC_MAX_COUNT

      return {
        exceeds,
        count: Math.min(zenvCommon.NEXT_PUBLIC_MAX_COUNT, totalCount),
      }
    }

    return { count: getCount(), pageParams: [], pages }
  }, [
    searchResults.data,
    searchResults.hasNextPage,
    searchResultsCount,
    fullTextSearchResults.data?.pages,
    fullTextSearchResults.isLoading,
    fullTextSearchResultsCount.count,
    fullTextSearchResultsCount.exceeds,
  ])

  const { isLoading } = searchResults
  const isFetching = searchResults.isFetching || fullTextSearchResults.isFetching
  const isError = searchResults.isError || fullTextSearchResults.isError
  const error = searchResults.error || fullTextSearchResults.error
  const hasNextPage = searchResults.hasNextPage || fullTextSearchResults.hasNextPage
  const isFetchingNextPage =
    searchResults.isFetchingNextPage || fullTextSearchResults.isFetchingNextPage

  const noQuery = docTypes.length === 0 && texts.length === 0

  return {
    setQuery,
    docTypes,
    searchResults: {
      data,
      error,
      isError,
      refetch,
      isLoading,
      isFetching,
      hasNextPage,
      fetchNextPage,
      isFetchingNextPage,
    },
    noQuery,
  }
}

export const mkDocsAndCount = (
  searchResults: DocSearchResults
): { docs: ZAugmentedDocWithHighlights[]; count: ZNumberExceed } => {
  const docs = searchResults.data?.pages.map((page) => page.data).flat() ?? []
  const count = searchResults.data?.count ?? { count: 0, exceeds: false }
  return { docs, count }
}

import {
  Box,
  Button,
  Center,
  Group,
  PasswordInput,
  ScrollArea,
  Stack,
  Text,
  TextInput,
  ThemeIcon,
  Title,
} from '@mantine/core'
import { useForm, zodResolver } from '@mantine/form'
import type { UseMutationResult } from '@tanstack/react-query'
import { FirebaseError } from 'firebase/app'
import React from 'react'
import { NextLinkOpt } from '~/client/components/util'
import { hooks } from '~/client/lib/hooks'
import { ZEmailPassword, useSignInWithRedirectErrorStore } from '~/client/lib/hooks/auth'
import { usePrefillEmailQueryParam } from '~/client/lib/query-params'
import {
  WrongAuthProviderError,
  type ZExternalProviderId,
} from '~/client/lib/wrong-auth-provider-error'
import { zenvCommon } from '~/common/zenv-common'
import LogoStars from '~/public/logo-stars.svg'
import GoogleLogo from '~/public/logo/google.svg'
import MicrosoftLogo from '~/public/logo/microsoft.svg'

export const AuthErrorMessage: React.FC<{ error: unknown }> = ({ error }) => {
  if (!error) return null

  if (error instanceof WrongAuthProviderError) {
    return (
      <Text data-testid='wrong-auth-provider' c='danger' size='xs'>
        {error.message}
      </Text>
    )
  }
  switch (error instanceof FirebaseError ? error.code : undefined) {
    // We want to hide whether the user exists or not from the user
    case 'auth/user-not-found':
    case 'auth/wrong-password':
      return (
        <Text data-testid='incorrect-email-or-password' c='danger' size='xs'>
          The email or password are incorrect. Please double-check the email address, retry logging
          in, or{' '}
          <NextLinkOpt c='primary' href='/signup'>
            create a new account
          </NextLinkOpt>
        </Text>
      )
    default:
      return (
        <Text data-testid='default-auth-error' c='danger' size='xs'>
          Unable to login. Please try again.
        </Text>
      )
  }
}

interface EmailPasswordProp {
  emailLoginSignup: UseMutationResult<void, unknown, ZEmailPassword>
  passwordLabel: string
  submitLabel: string
  hasResetPasswordLink?: boolean
}

export const EmailPasswordComp: React.FC<EmailPasswordProp> = ({
  emailLoginSignup,
  passwordLabel,
  submitLabel,
  hasResetPasswordLink = false,
}) => {
  const prefillEmail = usePrefillEmailQueryParam()
  const form = useForm<ZEmailPassword>({
    validate: zodResolver(ZEmailPassword),
    initialValues: {
      email: prefillEmail ?? '',
      password: '',
    },
  })

  return (
    <form
      onSubmit={form.onSubmit((data) => {
        // We do this to visually show the user that we're lowercasing their email
        form.setFieldValue('email', form.values.email.toLowerCase())
        emailLoginSignup.mutate(ZEmailPassword.parse(data))
      })}
    >
      <Stack gap='sm'>
        <TextInput
          autoFocus
          label='Email'
          type='email'
          required
          placeholder='name@example.com'
          {...form.getInputProps('email')}
        />
        <Stack gap='xs'>
          <PasswordInput label={passwordLabel} required {...form.getInputProps('password')} />
          {emailLoginSignup.error && <AuthErrorMessage error={emailLoginSignup.error} />}
        </Stack>
        <Group justify={hasResetPasswordLink ? 'space-between' : 'flex-end'}>
          {hasResetPasswordLink ? (
            <NextLinkOpt size='sm' href='/reset-password'>
              Forgot your password?
            </NextLinkOpt>
          ) : null}
          <Button
            loading={emailLoginSignup.isLoading}
            type='submit'
            data-testid='submit-email-password-form'
          >
            {submitLabel}
          </Button>
        </Group>
      </Stack>
    </form>
  )
}

const ExternalProviderSignInButton: React.FC<{
  label: string
  email?: string
  provider: ZExternalProviderId
  logo: React.ReactNode
}> = ({ provider, email, logo, label }) => {
  const { useExternalProviderMutation } = hooks.useAuthMethods()
  const loginMutation = useExternalProviderMutation(provider)
  const redirectError = useSignInWithRedirectErrorStore((state) => state.redirectError)
  const attemptedProvider = useSignInWithRedirectErrorStore((state) => state.attemptedProvider)
  // Make sure that we only show the error message on the corresponding provider
  const authErrorMessage = React.useMemo(() => {
    if (redirectError && provider === attemptedProvider) {
      return redirectError
    }
    return loginMutation.error
  }, [redirectError, provider, attemptedProvider, loginMutation.error])

  const errorIsMFA =
    authErrorMessage instanceof FirebaseError &&
    authErrorMessage.code === 'auth/multi-factor-auth-required'
  return (
    <>
      <Button
        size='lg'
        onClick={() => loginMutation.mutate(email)}
        // When users have phone auth enabled, they will have to wait until the
        // we send the text message on this screen, so we show this spinner to
        // indicate they have to wait a bit. This does not happen
        // if they use an Authenticator App.
        loading={loginMutation.isLoading || errorIsMFA}
      >
        <Group>
          <Box w={24} h={24}>
            {logo}
          </Box>
          <span>{label}</span>
        </Group>
      </Button>
      <AuthErrorMessage error={errorIsMFA ? undefined : authErrorMessage} />
    </>
  )
}

interface ProviderSignInButtonProps {
  label: string
  email?: string
}

export const GoogleSignInButton: React.FC<ProviderSignInButtonProps> = ({ label, email }) => {
  return (
    <ExternalProviderSignInButton
      provider='google.com'
      logo={<GoogleLogo />}
      label={label}
      email={email}
    />
  )
}

export const MicrosoftSignInButton: React.FC<ProviderSignInButtonProps> = ({ label, email }) => {
  if (!zenvCommon.NEXT_PUBLIC_ENABLE_MICROSOFT_LOGIN) return null

  return (
    <ExternalProviderSignInButton
      provider='microsoft.com'
      logo={<MicrosoftLogo />}
      label={label}
      email={email}
    />
  )
}

export const AuthenticationFrame: React.FC<{
  height?: number | string
  title: string
  subtitle?: string
  icon?: React.ReactNode
}> = ({ height, title, subtitle, icon, children }) => {
  return (
    <ScrollArea h={height ?? '100vh'}>
      <Center mih={height ?? '100vh'} py='xl'>
        <Stack w={360} gap='lg' h='100%' align='stretch' justify='center'>
          <Center mb='md'>
            <Box w={60}>
              <LogoStars alt='Aerial Stars Logo' />
            </Box>
          </Center>
          <Stack mb='md' gap='xs'>
            <Center>
              <Group>
                {icon && <ThemeIcon>{icon}</ThemeIcon>}
                <Title order={2} ta='center'>
                  {title}
                </Title>
              </Group>
            </Center>
            {subtitle && (
              <Text c='dimmed' ta='center' size='md'>
                {subtitle}
              </Text>
            )}
          </Stack>
          {children}
        </Stack>
      </Center>
    </ScrollArea>
  )
}

import {
  ActionIcon,
  Box,
  CopyButton,
  Group,
  Skeleton,
  Text,
  ThemeIcon,
  Tooltip,
} from '@mantine/core'
import { IconCheck, IconCopy, IconExternalLink } from '@tabler/icons-react'
import type { UseQueryOptions, UseQueryResult } from '@tanstack/react-query'
import type { inferProcedureOutput } from '@trpc/server'
import { useMemo } from 'react'
import { sortBy } from 'underscore'
import type {
  CustomComboboxItem,
  CustomComboboxParsedItemGroup,
} from '~/client/components/multi-select-creatable'
import { nestedClickArea } from '~/client/components/util'
import { LoadingErrorComp } from '~/client/components/util/error'
import { metadataRowHoverClasses } from '~/client/lib/css-util.css'
import { useCorpCryptId } from '~/client/lib/hooks'
import { hooks } from '~/client/lib/hooks/dependency-injection/interface'
import { getMetadata } from '~/client/lib/hooks/search/util'
import { docDisplay } from '~/common/doc'
import { Random } from '~/common/random'
import { ZAugmentedCorp, ZAugmentedDoc, getSchemaDisplayableFieldsInfo } from '~/common/schema'
import type { AugmentedMetadata } from '~/common/schema'
import { ZEquitySummary } from '~/common/schema/relation/'
import { ZAugmentedRelation, typeAugmentedRelationMap } from '~/common/schema/relation/augmented'
import type { AppRouter } from '~/common/trpc/app-router'

const random = new Random()

const quickAnswerValuePrefixes = [
  'qa-corp-meta',
  'qa-cap-table-summary',
  'qa-relation',
  'qa-doc',
] as const
export type QuickAnswerValuePrefix = (typeof quickAnswerValuePrefixes)[number]

interface MKOptionProps extends Pick<QuickAnswerData, 'url' | 'docs' | 'label' | 'metadata'> {
  queryResult?: Pick<UseQueryResult<unknown>, 'isLoading' | 'isError' | 'error'>
  valuePrefix: QuickAnswerValuePrefix
  display: string | undefined | null
  listPosition: number
}

export const isQuickAnswerOptionValue = (
  value: string | undefined
): value is QuickAnswerValuePrefix => {
  const prefixCandidate = value?.split('|')[0]
  return !!(prefixCandidate && quickAnswerValuePrefixes.includes(prefixCandidate))
}

export const extractQuickAnswersValuePrefix = (
  value: string | undefined
): { prefix: QuickAnswerValuePrefix; url?: string } | null => {
  if (!value) return null
  const [prefixCandidate, restOrExtra, rest] = value.split('|')
  if (!isQuickAnswerOptionValue(prefixCandidate)) return null
  return { prefix: prefixCandidate, url: rest ? restOrExtra : undefined }
}

const mkQuickAnswersValue = (
  valuePrefix: QuickAnswerValuePrefix,
  label: string,
  extra?: string
) => {
  const extraStr = extra ? `${extra}|` : ''
  return `${valuePrefix}|${extraStr}${label.replaceAll(' ', '-').toLowerCase()}`
}

const ValueComp: React.FC<{ value: string }> = ({ value }) => (
  <CopyButton value={value} timeout={2000}>
    {({ copied, copy }) => (
      <Tooltip
        label={copied ? 'Copied!' : 'Copy to clipboard'}
        color={copied ? 'primary' : undefined}
      >
        <Group
          className={`${metadataRowHoverClasses.hover} ${nestedClickArea.cssClass}`}
          pos='relative'
          pl={6}
          gap={2}
          onClick={copy}
        >
          <Box
            pos='absolute'
            left={0}
            right={0}
            top={0}
            bottom={0}
            className={metadataRowHoverClasses.appear}
            style={(theme) => ({
              border: `1px solid ${theme.colors.primary[7]}`,
              color: theme.colors.primary[7],
              borderRadius: 4,
            })}
          />
          <Text fz='sm' c='primary'>
            {value}
          </Text>
          <ActionIcon
            component='span'
            color={copied ? 'go' : 'primary'}
            className={metadataRowHoverClasses.appear}
          >
            {copied ? <IconCheck /> : <IconCopy />}
          </ActionIcon>
        </Group>
      </Tooltip>
    )}
  </CopyButton>
)

const convertToComboboxOption = ({
  display,
  key,
  label,
  queryResult,
}: QuickAnswerData): CustomComboboxItem => {
  const innerDisplay = !!display && <ValueComp value={display} />
  return {
    value: key,
    label,
    display: (
      <Group>
        <Text fw={500} fz='sm'>
          {label}
        </Text>
        <Group gap='xs'>
          {queryResult ? (
            <LoadingErrorComp
              variant='tooltip'
              loaderSize='xs'
              queryResult={queryResult}
              errorIconSize='xs'
              skeleton={<Skeleton height={18} width={`${random.randomInt(20) + 30}%`} />}
            >
              {innerDisplay}
            </LoadingErrorComp>
          ) : (
            innerDisplay
          )}
        </Group>
        <ThemeIcon color='primary' size='sm'>
          <IconExternalLink />
        </ThemeIcon>
      </Group>
    ),
  }
}

const mkQuickAnswerData = ({
  valuePrefix,
  label,
  url,
  ...props
}: MKOptionProps): QuickAnswerDataWithScore => ({
  key: mkQuickAnswersValue(valuePrefix, label, url),
  label,
  url,
  ...props,
})

export interface QuickAnswerData {
  key: string
  label: string
  display: string | null | undefined
  queryResult?: Pick<UseQueryResult, 'isLoading' | 'isError' | 'error'>
  url: string
  docs?: ZAugmentedDoc[]
  metadata?: AugmentedMetadata
}

interface QuickAnswerDataWithScore extends QuickAnswerData {
  listPosition: number
}

export type QuickAnswersRtn = inferProcedureOutput<AppRouter['quickAnswers']['search']>

const useQuickAnswerData = (props: QuickAnswersRtn | undefined): QuickAnswerData[] => {
  const { labels, relations = [], docs = [] } = props ?? {}
  const { mkCurrentCorpRoute } = useCorpCryptId()
  const corpQueryResult = hooks.trpc().corp.read.useQueryWithCorp()
  const equityDataQueryResult = hooks.trpc().relation.equity.equityData.useQueryWithCorp()

  const corpMetadataOptions = useMemo(() => {
    const { data } = corpQueryResult
    return getSchemaDisplayableFieldsInfo(ZAugmentedCorp).map(({ label, displayFn, getValue }) =>
      mkQuickAnswerData({
        queryResult: corpQueryResult,
        valuePrefix: 'qa-corp-meta',
        label,
        display: data && displayFn(data),
        // Since the labels are returned in order of relevancy and we want to show better results first,
        // add a score based on the position of the label in the returned list
        listPosition: labels?.findIndex((searchLabel) => searchLabel === label) ?? -1,
        url: mkCurrentCorpRoute('organizational-info', '?tab=metadata'),
        docs: data?.docOpts.filter(Boolean),
        metadata: data && getMetadata(getValue(data)),
      })
    )
  }, [corpQueryResult, labels, mkCurrentCorpRoute])

  const capTableSummaryOptions = useMemo(() => {
    const data = equityDataQueryResult.data?.equitySummary

    return getSchemaDisplayableFieldsInfo(ZEquitySummary).map(({ label, displayFn }) =>
      mkQuickAnswerData({
        queryResult: equityDataQueryResult,
        valuePrefix: 'qa-cap-table-summary',
        label,
        display: data && displayFn(data),
        listPosition: labels?.findIndex((searchLabel) => searchLabel === label) ?? -1,
        url: mkCurrentCorpRoute('cap-table', '?tab=summary'),
      })
    )
  }, [equityDataQueryResult, labels, mkCurrentCorpRoute])

  const relationOptions = useMemo(() => {
    return relations.flatMap((relation) =>
      getSchemaDisplayableFieldsInfo(typeAugmentedRelationMap[relation.type]).map(
        ({ label, displayFn, getValue }) => {
          return mkQuickAnswerData({
            valuePrefix: `qa-relation`,
            label: `Relation ${ZAugmentedRelation.displayFn(relation, false)} - ${label}`,
            display: displayFn(relation),
            listPosition: labels?.findIndex((searchLabel) => searchLabel === label) ?? -1,
            url: mkCurrentCorpRoute('relation', `${relation.cryptId.idStr}?tab=metadata`),
            docs: relation.docOpts.filter(Boolean),
            metadata: getMetadata(getValue(relation)),
          })
        }
      )
    )
  }, [labels, relations, mkCurrentCorpRoute])

  const docOptions = useMemo(() => {
    const schema = getSchemaDisplayableFieldsInfo(ZAugmentedDoc)
    return docs.flatMap((doc) =>
      schema.map(({ label, displayFn }) => {
        return mkQuickAnswerData({
          valuePrefix: 'qa-doc',
          label: `Doc ${docDisplay(doc)} - ${label}`,
          display: displayFn(doc),
          listPosition: labels?.findIndex((searchLabel) => searchLabel === label) ?? -1,
          url: mkCurrentCorpRoute('doc', doc.cryptId.idStr),
        })
      })
    )
  }, [labels, docs, mkCurrentCorpRoute])

  const items = useMemo(() => {
    const allItems = [
      ...corpMetadataOptions,
      ...capTableSummaryOptions,
      ...relationOptions,
      ...docOptions,
    ]
    if (!labels) return allItems
    // Remove items which were not in `labels`
    const filtered = allItems.filter((item) => item.listPosition !== -1)
    // Return items that were on top of the list first
    return sortBy(filtered, (item) => item.listPosition)
  }, [corpMetadataOptions, capTableSummaryOptions, relationOptions, docOptions, labels])

  return items
}

export const useQuickAnswerSearchOptions = (
  data: QuickAnswerData[]
): CustomComboboxParsedItemGroup => {
  const items = useMemo(() => data.map(convertToComboboxOption), [data])
  return { group: 'Quick Answers', items }
}

interface UseQuickAnswerSearchProps extends Pick<UseQueryOptions, 'keepPreviousData'> {
  query: string[]
}

interface UseQuickAnswerSearch {
  searchResults: UseQueryResult<QuickAnswersRtn>
  data: QuickAnswerData[]
}

export const useQuickAnswerSearch = ({
  query,
  ...opts
}: UseQuickAnswerSearchProps): UseQuickAnswerSearch => {
  const enabled = query.filter(Boolean).length > 0
  const searchResults = hooks
    .trpc()
    .quickAnswers.search.useQueryWithCorp({ query }, { ...opts, enabled })
  const data = useQuickAnswerData(enabled ? searchResults.data : undefined)
  return { searchResults, data }
}

import { CryptId } from '@cryptid-module'
import { useMutation, useQuery } from '@tanstack/react-query'
import type { UseTRPCQueryResult } from '@trpc/react-query/shared'
import type { MultiFactorResolver, User } from 'firebase/auth'
import React from 'react'
import { create } from 'zustand'
import type { Hooks } from '~/client/lib/hooks'
import { defaultTRPCMock } from '~/client/lib/hooks-mock/default-trpc-mock'
import type { AuthStore, ZEmailPassword } from '~/client/lib/hooks/auth'
import type { ZipFileStore } from '~/client/lib/hooks/zip-state'
import { CURRENT_AGREEMENT_VERSION } from '~/common/agreement'
import type { ZAugmentedCorp } from '~/common/schema'
import { mockEnhancedCorp } from '~/common/test/pipeline-mock'
import { appendPath } from '~/common/util'

// eslint-disable-next-line react-hooks/rules-of-hooks
const mockUseMutation = <TInput>() => useMutation<void, unknown, TInput, unknown>({})

export const useCurrentCorpMock = (
  isLoading?: boolean
): UseTRPCQueryResult<ZAugmentedCorp, unknown> =>
  Object.assign(
    useQuery<ZAugmentedCorp>(
      ['corp.read', isLoading],
      () => (isLoading ? new Promise<ZAugmentedCorp>(() => {}) : mockEnhancedCorp({})),
      { cacheTime: 0 }
    ),
    { trpc: { path: 'corp.read' } }
  )

export const defaultHooksMock: Hooks = {
  trpc: () => defaultTRPCMock.build(),
  useCorpCryptIdSafe: () => ({
    corpCryptId: new CryptId('hour96hy8hky8'),
    switchCurrentCorp: () => {},
    // Don't return the same path for every route, as that makes the navbar
    // think all the NavLinks are active
    mkCurrentCorpRoute: (topPath, subPath) => appendPath(topPath, subPath),
  }),
  useCurrentCorp: () => useCurrentCorpMock(),
  useAuthStore: create<AuthStore>()(() => ({
    isLoadingFirstTime: false,
    enrollMFA: false,
    user: { email: 'current-user@example.com', displayName: 'Bob Smith' } as User,
    passwordInfo: { isPasswordUser: false },
    skipEnrollMFA: () => {},
    setUser: () => Promise.resolve(),
    setEnrollMFA: () => {},
    setLoginResolver: () => {},
    setMFAVerificationRequired: () => {},
  })),
  useAuthMethods: () => ({
    user: undefined,
    authStatus: 'AUTHENTICATED',
    phoneAuth: {
      skipEnrollMFA: () => {},
      sendEnrollPhoneCodeMutation: mockUseMutation<string>(),
      enrollPhoneMutation: mockUseMutation<string>(),
      sendVerificationPhoneCodeMutation: mockUseMutation<MultiFactorResolver | undefined>(),
      verifyPhoneMutation: mockUseMutation<string>(),
      onError: (_: unknown) => {},
    },
    totpAuth: {
      enrollTotp: () => Promise.resolve(undefined),
      verifyEnrollTotp: () => Promise.resolve(),
      verifySignIn: () => Promise.resolve(),
    },
    logout: () => new Promise(() => {}),
    emailLoginMutation: mockUseMutation<ZEmailPassword>(),
    emailSignupMutation: mockUseMutation<ZEmailPassword>(),
    useExternalProviderMutation: () => mockUseMutation<string | undefined>(),
  }),
  useAuthStatus: () => 'AUTHENTICATED',
  // It is hard to mock a Response, and using `new Response()` does not work.
  useS3Upload: () => useMutation(() => Promise.resolve()),
  useCurrentUser: () => ({
    data: {
      cacheCorps: [],
      userEmail: 'user@example.com',
      agreementVersion: CURRENT_AGREEMENT_VERSION,
      superAdmin: false,
      notification: { type: 'disabled' },
    },
    isLoading: false,
  }),
  useZipFileStore: create<ZipFileStore>()(() => ({
    zipFileState: 'loading',
    data: undefined,
    failedDocuments: [],
    successfulDocuments: [],
    documentLinksSet: new Set<string>(),
    downloadProgress: { total: 0, current: 0 },
    preparationProgress: { total: 0, current: 0 },

    setZipFileState: () => {},
    addFailedDocument: () => {},
    addSuccessfulDocument: () => {},
    setData: () => {},
    setPreparationProgress: () => {},
    setDownloadProgress: () => {},
    resetDocuments: () => {},
  })),
  useZipFileMethods: () => ({
    download: () => Promise.resolve(),
    createZip: () => Promise.resolve(),
  }),
  useGenerateThumbnail: () => useMutation(() => Promise.resolve(new ArrayBuffer(8))),
  useDownloadS3: () => {
    return {
      downloadS3: () => Promise.resolve(),
      disabled: false,
      useQueryS3: () => useQuery(['useQueryS3'], () => []),
    }
  },
  useNavigationTabs: ({ defaultTab }) => {
    const [currentTab, setCurrentTab] = React.useState<string | null>(defaultTab)
    return { value: currentTab, onChange: (value) => setCurrentTab(value) }
  },
  useSearchQueries: () => ({ queries: [], setQueries: () => Promise.resolve() }),
  useIsIntegrationFetchingDocuments: () => false,
}

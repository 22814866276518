import type { EnhancedRelation } from '~/common/enhance'
import { AugmentedMetadataDate } from '~/common/schema'
import type { ZAugmentedRelation } from '~/common/schema/relation'
import type { DeletedRelation } from './deleted-relation'

export const getDateCellValue = (date?: Date | null): Cell =>
  date ? AugmentedMetadataDate.display(date) : undefined
type Cell = string | number | undefined | null

const defaultHeaders = ['Name', 'Start Date', 'End Date', 'Type', 'Number of Documents']
const defaultRowProvider = <R extends ZAugmentedRelation = ZAugmentedRelation>(
  r: EnhancedRelation<R>
): Cell[] => [
  r.display,
  getDateCellValue(r.startDate?.value),
  getDateCellValue(r.endDate?.value),
  r.type,
  r.docs.length,
]

export interface MkRelationsXLSXOpt<R extends ZAugmentedRelation = ZAugmentedRelation> {
  sheetTitle: string
  filename: string
  // Column headers, defaultHeaders will be used if not specified.
  headers?: string[]
  // Row Provider, defaultRowProvider will be used if not specified.
  rowProvider?: (r: EnhancedRelation<R>) => Cell[]
}

const loadXLSXLibAsync = (() => {
  const _loadLibrary = () => import('xlsx')
  let _libPromise: ReturnType<typeof _loadLibrary> | undefined
  return () => {
    if (_libPromise) {
      return _libPromise
    }
    _libPromise = _loadLibrary()
    return _libPromise
  }
})()

export const mkRelationsXLSX = async <R extends ZAugmentedRelation = ZAugmentedRelation>(
  relations: (EnhancedRelation<R> | DeletedRelation)[],
  options?: MkRelationsXLSXOpt<R>
): Promise<Blob> => {
  const XLSX = await loadXLSXLibAsync()
  const columns = options?.headers ?? defaultHeaders
  const data = relations.map((relation) =>
    relation.type === 'DELETED'
      ? // fills the rest of the columns with `null`. Without this, the spreadsheet
        // has weird formatting issues
        [relation.display, ...new Array(columns.length - 1).fill(null)]
      : options?.rowProvider?.(relation) || defaultRowProvider(relation)
  )

  const worksheet = XLSX.utils.aoa_to_sheet([columns, ...data])
  XLSX.utils.sheet_add_aoa(worksheet, [columns], { origin: 'A1' })

  // Adjust the column width to make it looking good at first open
  const wsCols = data
    .reduce<number[]>(
      (prev, current) =>
        current.map((value, i) => Math.max(value?.toString().length ?? 0, prev[i] ?? 0)),
      columns.map((c) => c.toString().length)
    )
    .map((w) => ({ wch: w }))
  worksheet['!cols'] = wsCols

  const workbook = XLSX.utils.book_new()
  XLSX.utils.book_append_sheet(workbook, worksheet, options?.sheetTitle ?? 'Relations')
  if (options?.filename) {
    XLSX.writeFileXLSX(workbook, options.filename, { compression: true })
  }
  // Also return buffer for the zip file generation
  const buffer = XLSX.writeXLSX(workbook, { type: 'buffer', compression: true })
  return new Blob([buffer], {
    /* cspell:disable-next-line */
    type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
  })
}

import { Table } from '@mantine/core'
import React from 'react'
import type { DetailTableProps } from '~/client/components/relation/detail/types'
import { MetadataRow } from '~/client/components/util/metadata'
import type { ZUpdateConvertibleNote } from '~/common/schema/relation'
import { ZAugmentedConvertibleNote } from '~/common/schema/relation'

export const ConvertibleMetadataTable: React.FC<
  DetailTableProps<ZUpdateConvertibleNote, ZAugmentedConvertibleNote>
> = ({ data, update, size }) => (
  <Table striped>
    <Table.Tbody>
      <MetadataRow
        displayProps={ZAugmentedConvertibleNote.shape.party.mkDisplayProps(data, update, 'party')}
        autofill={data.autofill}
        size={size}
      />
      <MetadataRow
        displayProps={ZAugmentedConvertibleNote.shape.startDate.mkDisplayProps(
          data,
          update,
          'startDate'
        )}
        autofill={data.autofill}
        size={size}
      />
      <MetadataRow
        displayProps={ZAugmentedConvertibleNote.shape.endDate.mkDisplayProps(
          data,
          update,
          'endDate'
        )}
        size={size}
      />
      <MetadataRow
        displayProps={ZAugmentedConvertibleNote.shape.investment.mkDisplayProps(
          data,
          update,
          'investment'
        )}
        size={size}
      />
    </Table.Tbody>
  </Table>
)

import { z } from 'zod'
import { AugmentedMetadataString } from '~/common/schema/metadata'
import { ZStateEnum } from '~/common/schema/relation/state-enum'
import { util } from '~/common/schema/relation/util'
import { withDisplaySchema } from '~/common/schema/with-display-schema'

export const stateCorporationShape = {
  type: z.literal('STATE'),
  state: withDisplaySchema(ZStateEnum.optional(), 'state', 'State'),
}

export const { ZUpdate: ZUpdateStateCorporation, ZAugmented: ZAugmentedStateCorporation } =
  util.createSchemas(
    {
      updateShape: stateCorporationShape,
      augmentedShape: stateCorporationShape,
      defaultValue: {},
    },
    {
      requiredTypes: [],
      optionalTypes: ['MISCELLANEOUS'],
      display: 'State Documents',
      displayFn: (obj) => `${obj.state ? `${obj.state} ` : ''}State Documents`,
      identifyingField: undefined,
      tooltipContentFn: (obj, shape) => [[shape.state.displaySchema.display, obj.state]],
    }
  )

export interface ZUpdateStateCorporation extends z.infer<typeof ZUpdateStateCorporation> {}
export interface ZAugmentedStateCorporation extends z.infer<typeof ZAugmentedStateCorporation> {}

const localCorporationAugmentedShape = {
  type: z.literal('LOCAL'),
  jurisdiction: withDisplaySchema(AugmentedMetadataString, 'string', 'Jurisdiction'),
}

export const { ZUpdate: ZUpdateLocalCorporation, ZAugmented: ZAugmentedLocalCorporation } =
  util.createSchemas(
    {
      updateShape: localCorporationAugmentedShape,
      augmentedShape: localCorporationAugmentedShape,
      defaultValue: { jurisdiction: { value: '', type: 'edited' } },
    },
    {
      requiredTypes: [],
      optionalTypes: ['MISCELLANEOUS'],
      display: 'Local Documents',
      displayFn: (obj) =>
        `${obj.jurisdiction.value ? `${obj.jurisdiction.value} ` : ''}Local Documents`,
      identifyingField: undefined,
      tooltipContentFn: (obj, shape) => [
        [
          shape.jurisdiction.displaySchema.display,
          AugmentedMetadataString.display(obj.jurisdiction.value),
        ],
      ],
    }
  )

export interface ZUpdateLocalCorporation extends z.infer<typeof ZUpdateLocalCorporation> {}
export interface ZAugmentedLocalCorporation extends z.infer<typeof ZAugmentedLocalCorporation> {}

export const ZStateLocalType = util.annotateDisplay(
  z.enum([ZAugmentedStateCorporation.type, ZAugmentedLocalCorporation.type]),
  'State and Local'
)
export type ZStateLocalType = z.infer<typeof ZStateLocalType>

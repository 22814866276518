import { CryptId } from '@cryptid-module'
import type { WithId } from 'mongodb'
import type { z } from 'zod'
import {
  type EnhancedCorp,
  type EnhancedDoc,
  type EnhancedRelation,
  enhanceCorp,
  enhanceDoc,
  enhanceRelation,
} from '~/common/enhance'
import type { WithIndexNumber, ZAugmentedDoc } from '~/common/schema'
import { missingValuesEquitySummaryWarning } from '~/common/schema'
import type { ZAugmentedRedFlag } from '~/common/schema/red-flag'
import type {
  AllAugmentedRelationsMap,
  ZAugmentedRelation,
  ZAugmentedRelationPartial,
  ZEquitySummary,
  ZRelationTypeValues,
} from '~/common/schema/relation'
import { typeAugmentedRelationMap } from '~/common/schema/relation'
import type { ZMongoRelation, ZMongoRelationPartial } from '~/server/mongo/schema/relation'
import type { ZS3SignedUrls } from '~/server/s3'

export const corpCryptId = new CryptId('r18fQQii-H0PE6x2')

export const relationCryptId = new CryptId('stubCryptId')
export const redFlagCryptId = new CryptId('stubCryptId')

export const fixedDate = new Date(2020, 1, 1)

type Value = string | Date | number

export const mkEditedValue = <V extends Value>(value: V): { type: 'edited'; value: V } => ({
  type: 'edited',
  value,
})

interface Edited<V> {
  type: 'edited'
  value: V | null
}

export const mockBaseCorp = (): { name: Edited<string>; startDate: Edited<Date> } => ({
  name: mkEditedValue('Stub corp'),
  startDate: mkEditedValue(fixedDate),
})

interface MockBaseRelation extends Pick<ZMongoRelation, 'index'> {
  party?: { name: string; email?: string }
}

export const mockBaseRelation = (
  type: ZRelationTypeValues,
  relation: ZMongoRelationPartial | ZAugmentedRelationPartial
): MockBaseRelation => {
  const relationParty = 'party' in relation ? relation.party : undefined
  // Some relation types (like LOCAL) don't have a party
  const partyFallback =
    'party' in typeAugmentedRelationMap[type].shape
      ? {
          name: 'Stub name',
          email: 'stub@email.com',
        }
      : undefined
  const party = relationParty ?? partyFallback

  return {
    index: 1,
    ...(party ? { party } : {}),
  }
}

interface MockBaseDocRtn extends ZS3SignedUrls {
  cryptId: CryptId
}

export const mockBaseDoc = (): MockBaseDocRtn => {
  return { cryptId: new CryptId('stub'), file: 'stub file', thumb: 'stub thumb' }
}

export interface MockEnhancedDoc extends Partial<Omit<WithIndexNumber<ZAugmentedDoc>, 'type'>> {
  type: ZAugmentedDoc['type']
}

/**
 * Creates mocked enhanced doc All values, including
 * `_id` can be overridden. Note that `cryptId` is just a stub and not the
 * actual encrypted `_id`` value
 * @param augmented doc - ZAugmentedDoc to mock
 * @returns
 */

export const mockEnhancedDoc = ({ type, ...rest }: MockEnhancedDoc): EnhancedDoc =>
  enhanceDoc({
    createdAt: new Date(),
    sha256: 'stub sha256',
    corpCryptId,
    url: 'stub url',
    cryptId: new CryptId('stub7886cryptId'),
    thumb: '/sample-thumb.png',
    indexNumber: 1,
    type,
    title: 'Sample Doc Title',
    ...rest,
  })

export type MockEnhancedRelationMap = {
  // eslint-disable-next-line custom-rules/prefer-extends-to-type-intersection
  [K in keyof AllAugmentedRelationsMap]: Partial<
    Omit<WithId<z.infer<AllAugmentedRelationsMap[K]>>, 'type' | 'docOpts'>
  > & {
    type: K
    mockDocs?: MockEnhancedDoc[]
  }
}

// Union type of all the types in AllRelationsMap, without the default mocked properties
export type MockEnhancedRelation = MockEnhancedRelationMap[keyof MockEnhancedRelationMap]

export const mockEnhancedRelation = ({
  type,
  mockDocs,
  ...relationSpecificParams
}: MockEnhancedRelation): EnhancedRelation => {
  const party = 'party' in relationSpecificParams ? relationSpecificParams.party : undefined
  const docOpts = mockDocs?.map((doc) => mockEnhancedDoc({ party, ...doc })) ?? []
  const docCryptIds = docOpts.map((doc) => doc.cryptId)

  return enhanceRelation({
    ...mockBaseRelation(type, relationSpecificParams),
    type,
    corpCryptId,
    url: '/',
    cryptId: relationCryptId,
    docCryptIds,
    docOpts,
    ...relationSpecificParams,
  } as ZAugmentedRelation)
}

export interface MockEnhancedCorp extends Omit<EnhancedCorp, 'docOpts'> {
  docOpts: EnhancedDoc[]
}

interface MockEnhancedCorpProps extends Partial<MockEnhancedCorp> {
  mockDocs?: MockEnhancedDoc[]
}

export const mockEnhancedCorp = ({
  mockDocs,
  ...rest
}: MockEnhancedCorpProps): MockEnhancedCorp => {
  const docOpts = mockDocs?.map((doc) => mockEnhancedDoc({ ...doc })) ?? []
  const docCryptIds = docOpts.map((doc) => doc.cryptId)

  return {
    ...enhanceCorp({
      ...mockBaseCorp(),
      cryptId: corpCryptId,
      docCryptIds,
      docOpts,
      ...rest,
    }),
    docOpts,
  } as MockEnhancedCorp
}

// mock the index numbers that should come from the db query
export const withMockIndexNumber = <T extends object>(objects: T[]): WithIndexNumber<T>[] =>
  objects.map((object, key) => ({
    ...object,
    indexNumber: key + 1,
  }))

export { enhanceRelation } from '~/common/enhance'

export const mockAugmentedRedFlag = (props: Partial<ZAugmentedRedFlag>): ZAugmentedRedFlag =>
  ({
    active: true,
    corpCryptId,
    text: 'stub text',
    createdBy: 'example@email.com',
    cryptId: redFlagCryptId,
    display: 'Stub Red Flag',
    primaryCryptId: redFlagCryptId,
    type: 'ADVISOR_REQUIRES_ADVISOR_AGREEMENT',
    ...props,
  }) as ZAugmentedRedFlag

export const equitySummary: ZEquitySummary = {
  optionPoolTotal: {
    value: 30000,
  },
  commonPoolShares: {
    value: 400000,
  },
  commonShares: {
    value: 50000,
    warning: missingValuesEquitySummaryWarning,
  },
  warrantShares: {
    value: 50000,
    warning: missingValuesEquitySummaryWarning,
  },
  fullyDiluted: {
    value: 20000000,
    warning: missingValuesEquitySummaryWarning,
  },
  optionPoolRemaining: {
    value: 6500000,
  },
  optionPoolUsed: {
    value: 1200000,
  },
  optionShares: {
    value: 3000000,
    warning: missingValuesEquitySummaryWarning,
  },
  preferredShares: {
    value: 600000,
  },
  totalFunding: {
    value: 5000000,
  },
  totalShares: {
    value: 10,
  },
}

import { z } from 'zod'
import {
  AugmentedMetadataDate,
  AugmentedMetadataPrice2,
  ZParty,
  withDisplaySchema,
} from '~/common/schema'
import { util } from '~/common/schema/relation/util'

const convertibleNoteAugmentedShape = {
  type: z.literal('CONVERTIBLE'),
  party: withDisplaySchema(ZParty, 'party', 'Equity Holder'),
  investment: withDisplaySchema(AugmentedMetadataPrice2.optional(), 'price2', 'Investment'),
  startDate: withDisplaySchema(AugmentedMetadataDate.optional(), 'date', 'Issue Date'),
  endDate: withDisplaySchema(
    AugmentedMetadataDate.optional(),
    'date',
    'Conversion or Termination Date'
  ),
}

export const { ZUpdate: ZUpdateConvertibleNote, ZAugmented: ZAugmentedConvertibleNote } =
  util.createSchemas(
    {
      updateShape: convertibleNoteAugmentedShape,
      augmentedShape: convertibleNoteAugmentedShape,
      defaultValue: { party: { name: '' } },
    },
    {
      requiredTypes: [
        'CONVERTIBLE_NOTE', // Primary Document
        'BOARD_CONSENT_AND_MINUTES',
      ],
      optionalTypes: ['MISCELLANEOUS', 'STOCKHOLDER_CONSENT'],
      display: 'Convertible Note',
      displayFn: (obj) => `Convertible Note ${obj.party.name}`,
      identifyingField: 'party.name',
      tooltipContentFn: (obj, shape) => [
        [shape.party.displaySchema.display, ZParty.display(obj.party)],
        [
          shape.startDate.displaySchema.display,
          AugmentedMetadataDate.display(obj.startDate?.value),
        ],
        [shape.endDate.displaySchema.display, AugmentedMetadataDate.display(obj.endDate?.value)],
        [
          shape.investment.displaySchema.display,
          AugmentedMetadataPrice2.display(obj.investment?.value),
        ],
      ],
    }
  )
export interface ZUpdateConvertibleNote extends z.infer<typeof ZUpdateConvertibleNote> {}
export interface ZAugmentedConvertibleNote extends z.infer<typeof ZAugmentedConvertibleNote> {}

import { Group, Stack, Text } from '@mantine/core'
import { DisplayDate } from '~/client/components/util/date-range'
import { HoverOnClippedText, PrimaryBadge } from '~/client/components/util/doc'
import { docDisplay } from '~/common/doc'
import type { ZAugmentedDoc } from '~/common/schema'

const textStyle = {
  maxWidth: '300px',
  textOverflow: 'ellipsis',
  whiteSpace: 'nowrap',
  overflow: 'hidden',
  cursor: 'text',
  width: 'fit-content',
} as const

export const DocCardData: React.FC<{ doc: ZAugmentedDoc | undefined }> = ({ doc }) => {
  if (!doc) throw new Error('No doc. Should never happen.')
  const { party, startDate } = doc

  return (
    <Group
      align='flex-start'
      p='16px'
      wrap='nowrap'
      justify='space-between'
      flex={1}
      data-testid='doc-card-with-doc'
    >
      <Stack gap='xs' flex={1}>
        <HoverOnClippedText
          textStyle={{
            ...textStyle,
            fontWeight: 'bold',
          }}
          text={docDisplay(doc)}
        />
        {party && <HoverOnClippedText textStyle={textStyle} text={party.name} />}
        <Text style={textStyle}>
          <DisplayDate startDate={startDate} />
        </Text>
      </Stack>
      <Stack flex='none' gap='xs' align='flex-end'>
        <PrimaryBadge>Active</PrimaryBadge>
      </Stack>
    </Group>
  )
}

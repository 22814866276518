import { ActionIcon, Group, Loader, Table } from '@mantine/core'
import { IconArrowsSort, IconSortAscending, IconSortDescending } from '@tabler/icons-react'
import type { PropsWithChildren } from 'react'
import { useCallback, useState } from 'react'
import { theme } from '~/client/lib/theme'

/**
 * @sortField is the field sorted
 * @direction is the direction @sortField is sorted
 */
export interface SortParams<Field extends string> {
  sortField: Field
  direction: -1 | 1
}

/**
 * @toggle responds to click on a given sort field
 */
export interface UseSortStateRtn<Field extends string> extends SortParams<Field> {
  toggle: (sortField: Field) => void
  isSorting: boolean
  setIsSorting: (isSorting: boolean) => void
}

const useSortState = <Field extends string>(
  defaultParams: SortParams<Field>,
  isTrinary: boolean
) => {
  const [current, setCurrent] = useState<SortParams<Field>>({ ...defaultParams })
  const [isSorting, _setIsSorting] = useState(false)
  const toggle = useCallback(
    (sortField: Field) => {
      setCurrent((prevParams): SortParams<Field> => {
        const { sortField: prevSortField, direction: prevDirection } = prevParams
        if (prevSortField !== sortField) {
          return {
            sortField,
            direction: 1,
          }
        }
        // if we've reach the final order of the sort field (descending),
        // return to default sort
        if (isTrinary && prevDirection === -1) {
          return { ...defaultParams }
        }
        return {
          sortField,
          direction: prevDirection === 1 ? -1 : 1,
        }
      })
    },
    [defaultParams, isTrinary]
  )
  const setIsSorting = useCallback(
    (v: boolean) => {
      // Avoid infinite re renders
      if (v !== isSorting) _setIsSorting(v)
    },
    [isSorting]
  )

  return { ...current, toggle, isSorting, setIsSorting }
}

export const useBinarySort = <Field extends string>(
  defaultParams: SortParams<Field>
): UseSortStateRtn<Field> => useSortState(defaultParams, false)

export const useTrinarySort = <Field extends string>(
  defaultParams: SortParams<Field>
): UseSortStateRtn<Field> => useSortState(defaultParams, true)

const SortButton: React.FC<{
  direction: 1 | -1
  isActive: boolean
  isSorting?: boolean
  onClick: () => void
}> = ({ direction, isActive, isSorting, onClick }) => (
  <ActionIcon onClick={onClick} size='sm' color={isActive ? 'primary' : 'gray'}>
    {isActive ? (
      isSorting ? (
        <Loader size='xs' />
      ) : direction === 1 ? (
        <IconSortAscending />
      ) : (
        <IconSortDescending />
      )
    ) : (
      <IconArrowsSort />
    )}
  </ActionIcon>
)

export interface SortHeaderProps<Field extends string> {
  /**
   * Current field
   */
  sortField: Field
  /**
   * Global sort state
   */
  sortState: UseSortStateRtn<Field>
}

interface SortHeaderCompProps<Field extends string>
  extends SortHeaderProps<Field>,
    React.HTMLAttributes<HTMLTableCellElement> {}

export const SortHeader = <Field extends string>({
  children,
  sortField,
  sortState,
  ...thProps
}: PropsWithChildren<SortHeaderCompProps<Field>>): JSX.Element => {
  return (
    <Table.Th {...thProps}>
      <Group
        style={{
          flexWrap: 'nowrap',
          gap: theme.spacing.xs,
        }}
      >
        <span
          style={{
            whiteSpace: 'nowrap',
          }}
        >
          {children}
        </span>
        <SortButton
          onClick={() => sortState.toggle(sortField)}
          isActive={sortState.sortField === sortField}
          direction={sortState.direction}
          isSorting={sortState.isSorting}
        />
      </Group>
    </Table.Th>
  )
}

import { Button, Group, Table } from '@mantine/core'
import { IconDownload } from '@tabler/icons-react'
import React from 'react'
import { groupBy } from 'underscore'
import type { z } from 'zod'
import { RelationRowsSkeleton } from '~/client/components/relation/base-table/skeleton'
import { FullScreenMsg } from '~/client/components/util'
import { LoadingErrorComp } from '~/client/components/util/error'
import { Pagination } from '~/client/components/util/pagination'
import { useTrinarySort } from '~/client/components/util/sort'
import { hooks, useCurrentCorpAuth, usePagination } from '~/client/lib/hooks'
import { useRelationsByType } from '~/client/lib/hooks/query'
import { useMkRelationsXLSX } from '~/client/lib/hooks/relations-xlsx'
import { type MkRelationsXLSXOpt } from '~/client/lib/relations-xlsx'
import type { EnhancedRelation, RedFlagInfo } from '~/common/enhance'
import { enhanceRelation, getRelationRedFlagInfo } from '~/common/enhance'
import type { ZRelationPaginationSortField } from '~/common/pagination'
import type { AllAugmentedRelationsMap, ZRelationTypeValues } from '~/common/schema/relation'
import type { NonEmptyArray } from '~/common/util-types'
import type { SortRenderer } from './util'
import { AddRelation } from './util'

interface RelationTableProps<RelationTypes extends ZRelationTypeValues> {
  noStatus?: boolean
  relationTypes: NonEmptyArray<RelationTypes>
  renderHeaders: SortRenderer
  renderRows: (
    relationsData: {
      enhancedRelation: EnhancedRelation<z.infer<AllAugmentedRelationsMap[RelationTypes]>>
      redFlagInfo: RedFlagInfo | undefined
    }[]
  ) => React.ReactNode
  noDataMsg: string
  downloadOptions: MkRelationsXLSXOpt<z.infer<AllAugmentedRelationsMap[RelationTypes]>>
}

const RelationTableComp = <RelationTypes extends ZRelationTypeValues>({
  relationTypes,
  renderHeaders,
  renderRows,
  noDataMsg,
  noStatus,
  downloadOptions,
}: RelationTableProps<RelationTypes>) => {
  const { data: auth } = useCurrentCorpAuth()
  const sortState = useTrinarySort<ZRelationPaginationSortField>({ sortField: '_id', direction: 1 })
  const pagination = usePagination()

  const relationsQueryResult = useRelationsByType(
    {
      ...pagination.getQueryParams(),
      types: relationTypes,
      sortField: sortState.sortField,
      direction: sortState.direction,
    },
    {
      // Purpose: To eliminate page flickering due to queryKey update, use keepPreviousData option
      keepPreviousData: true,
    }
  )

  const primaryCryptIds = React.useMemo(
    () => relationsQueryResult.data?.data.map((relation) => relation.cryptId) ?? [],
    [relationsQueryResult.data]
  )

  const redFlagQueryEnabled = !!primaryCryptIds.length && !!auth && auth.level !== 'investor'

  const redFlagsQueryResult = hooks
    .trpc()
    .redFlags.get.byPrimaryIds.useQueryWithCorp(
      { primaryCryptIds },
      { enabled: redFlagQueryEnabled }
    )

  const queryResult = {
    isFetching: relationsQueryResult.isFetching || redFlagsQueryResult.isFetching,
    isLoading:
      relationsQueryResult.isLoading || (redFlagQueryEnabled && redFlagsQueryResult.isLoading),
    isError: relationsQueryResult.isError || redFlagsQueryResult.isError,
    error: relationsQueryResult.error || redFlagsQueryResult.error,
  }
  const relationRedFlagsMap =
    redFlagsQueryResult.data &&
    groupBy(redFlagsQueryResult.data, (redFlag) => redFlag.primaryCryptId.idStr)

  sortState.setIsSorting(queryResult.isFetching)

  const headerRef = React.useRef<HTMLTableSectionElement>(null)
  pagination.setTotalItems(relationsQueryResult.data?.count)

  const relationsData = relationsQueryResult.data?.data.map((relation) => {
    const enhancedRelation = enhanceRelation(relation)
    const redFlagInfo =
      relationRedFlagsMap &&
      getRelationRedFlagInfo(enhancedRelation, relationRedFlagsMap[relation.cryptId.idStr] ?? [])

    return { enhancedRelation, redFlagInfo }
  })

  const relationsXlsx = useMkRelationsXLSX()
  const onClickDownload = async () => {
    await relationsXlsx.mkXlsx(relationTypes, downloadOptions)
  }

  return (
    <>
      {relationsData?.length === 0 ? (
        <FullScreenMsg title={noDataMsg}>
          <AddRelation options={relationTypes} />
        </FullScreenMsg>
      ) : (
        <>
          <Table highlightOnHover data-testid='relation-table'>
            <Table.Thead ref={headerRef}>{renderHeaders({ sortState })}</Table.Thead>
            <Table.Tbody>
              <LoadingErrorComp
                queryResult={queryResult}
                skeleton={
                  <RelationRowsSkeleton
                    noStatus={noStatus}
                    headerRef={headerRef}
                    rows={pagination.pageSize}
                  />
                }
                fullTableWidth
              >
                {relationsData && renderRows(relationsData)}
              </LoadingErrorComp>
            </Table.Tbody>
          </Table>
          <Group justify='space-between'>
            <Pagination
              styles={{ mt: 'sm' }}
              {...pagination}
              queryResult={{ ...relationsQueryResult, ...queryResult }}
            />
            <Button
              size='sm'
              leftSection={<IconDownload />}
              loading={relationsXlsx.isLoading}
              onClick={onClickDownload}
            >
              Download
            </Button>
          </Group>
        </>
      )}
    </>
  )
}

export const RelationTable = <RelationTypes extends ZRelationTypeValues>(
  props: RelationTableProps<RelationTypes>
): JSX.Element => (
  // The key is necessary to reset pagination, loading and sorting state when
  // the user switches to a different table using the navbar
  <RelationTableComp {...props} key={props.relationTypes.toString()} />
)

import "../../node_modules/.pnpm/@vanilla-extract+webpack-plugin@2.3.1_@types+node@20.8.7_less@4.2.0_sass@1.63.3_sugarss@4.0.1_ibdzz5xsvpr6dbwgydw6b4qj54/node_modules/@vanilla-extract/webpack-plugin/vanilla.virtual.css?%7B%22fileName%22%3A%22client%2Flib%2Fcss-util.css.ts.vanilla.css%22%2C%22source%22%3A%22%23H4sIAAAAAAAAA8WV226jMBCG7%2FMUo0orJVIdGXJo69ys9hn2brUXBobgBmxkJglVlXdf4UBqcirbvVgpJob5559vRib5vsG31MoCK3h93ZdplcL7CIB%2Fc18AZKWuUmMLcdzmknDMJ6sRwGEEMJvdFQZYnKTL5V0p87UBHwZwGE2P1BzaTeDSTCljRW8CeKNzeYqU0aKLAC8qQFkhU3rl2YjM7NDeMAsGmfkSlmAuG4yi8qqEnf9sIGywuI4b9nFnA3HP7K4BO4lXad7VWAxEDm8gz%2FvIi4HI4efI4Rnysqvx9I%2FIyz7y09Ap88%2BnzPvIz87aN4tMzapMJmYPfDo72TGzpcbyIyyAw7ys3bLrSI75I7SfaRBO%2FCJtO%2B8X%2Bc9l7db9%2FJe%2Fg%2FzI8%2Bp%2BucWwrN26jyh%2FJZIkS1QloxyT365maZQmtAx3qKkSIPPcS4m%2B2FV0Y5rngHwxAV7WEJQ1zMq62T%2FeFIXusihrYIurunlrdro0uvODOKyBGChx9LHJjRWwk3bMWCE1KY3MPWRKy5jUDtmLP%2BU2bWurJq8dby8%2B7Wx%2BYk3MGkOPcBn8IZN1A6bbMXaOhLUPikJEmBqLLa0m1CTg4aHpe68SykQz2eaukHatNMsxJQEsWB6fZqjWGQno7hNVle41jHITb9xZk%2FFmbc1WJ6ydxj5ThJ5lZIhMcTL14WRKXQP%2Fh41MeQm2dkRSq0K6Xx4tCxTdX%2F2qF0q2Vh5PDp%2BGVT%2BmCI9BFptt01qwGh3%2BANZxZ4A8CAAA%22%7D"
export var boxShadowHover = 'jjwpfs8';
export var docDetailButtonClass = 'jjwpfsa';
export var hoverAppearDelay150msClasses = {hover:'jjwpfs2',appear:'jjwpfs3'};
export var hoverAppearDelay250msClasses = {hover:'jjwpfs4',appear:'jjwpfs5'};
export var hoverAppearInstantClasses = {hover:'jjwpfs0',appear:'jjwpfs1'};
export var integrationCardBoxShadowClass = 'jjwpfsb';
export var metadataRowHoverClasses = {hover:'jjwpfs6',appear:'jjwpfs7'};
export var miniDocBoxShadowHover = 'jjwpfs9';
export var navLinkChildrenPseudoClass = 'jjwpfse';
export var relationRowColorInActiveClass = 'jjwpfsc';
export var relationRowCursorClass = 'jjwpfsd';
export var shakeOnceAnimationClass = 'jjwpfsg';
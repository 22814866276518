import type { CryptId } from '@cryptid-module'
import type { UseMutationResult } from '@tanstack/react-query'
import React from 'react'
import { useDocDetailState } from '~/client/components/doc-detail/state'
import { hooks, useCorpCryptId } from '~/client/lib/hooks/index'
import { optimisticMutationOptions } from '~/client/lib/hooks/optimistic-update'
import type { RelationsSearchParams } from '~/client/lib/hooks/search'
import { optimisticSetLink } from '~/client/lib/util'
import type { ZAugmentedRelation } from '~/common/schema/relation'

interface SetLinkInput {
  relationCryptId: CryptId
  docCryptId: CryptId
  linkValue: boolean
}

export const useSetLinkToRelation = (
  searchQueryParams: RelationsSearchParams,
  docCryptId: CryptId
): UseMutationResult<void, unknown, { relationCryptId: CryptId; linkValue: boolean }> => {
  const { corpCryptId } = useCorpCryptId()
  const utils = hooks.trpc().useContext()

  const optimisticUpdate = (input: SetLinkInput, oldData: ZAugmentedRelation[]) => {
    const relationIndex = oldData.findIndex((rel) => rel.cryptId.equals(input.relationCryptId))
    const relation = oldData[relationIndex]
    if (relationIndex === -1 || !relation) return oldData
    return [
      ...oldData.slice(0, relationIndex),
      optimisticSetLink(relation, input.docCryptId, input.linkValue),
      ...oldData.slice(relationIndex + 1),
    ]
  }

  const _setLink = hooks.trpc().relation.setLink.useMutationWithCorp(
    optimisticMutationOptions(
      utils.relations.search,
      { ...searchQueryParams, corpCryptId },
      (input: SetLinkInput, oldData) => {
        if (!oldData) return oldData
        return {
          ...oldData,
          data: optimisticUpdate(input, oldData.data),
        }
      }
    )
  )

  const setLinkWithDoc = React.useCallback(
    ({ relationCryptId, linkValue }) =>
      _setLink.mutateAsync({ linkValue, relationCryptId, docCryptId }),
    [_setLink, docCryptId]
  )
  return {
    ..._setLink,
    mutateAsync: setLinkWithDoc,
    mutate: setLinkWithDoc,
  }
}

export type SetLinkMutation = UseMutationResult<void, unknown, { linkValue: boolean }>

interface UseDocLinksRtn {
  /**
   * Used in the "Relations" tab to link/unlink the doc to the corp
   */
  setLinkToCorp: SetLinkMutation
  /**
   * Used in the "Relations" tab to display the link/unlink button
   */
  isLinkedToCorp: boolean
}

export const useDocLinks = (): UseDocLinksRtn => {
  const docCryptId = useDocDetailState((state) => state.docCryptId)
  const { data } = hooks.useCurrentCorp()
  const { corpCryptId } = useCorpCryptId()
  const utils = hooks.trpc().useContext()

  const _setLinkToCorp = hooks.trpc().corp.setLink.useMutationWithCorp({
    ...optimisticMutationOptions(utils.corp.read, { corpCryptId }, (input, oldData) => {
      if (!oldData) return oldData

      return optimisticSetLink(oldData, input.docCryptId, input.linkValue)
    }),
  })

  const setLinkToCorpWithDoc = React.useCallback(
    async ({ linkValue }) => {
      if (docCryptId) await _setLinkToCorp.mutateAsync({ linkValue, docCryptId })
    },
    [docCryptId, _setLinkToCorp]
  )
  const setLinkToCorp = {
    ..._setLinkToCorp,
    mutateAsync: setLinkToCorpWithDoc,
    mutate: setLinkToCorpWithDoc,
  }
  const corpDocCryptIds = data?.docCryptIds

  const isLinkedToCorp = React.useMemo(() => {
    if (!docCryptId || !corpDocCryptIds) return false
    return corpDocCryptIds.some((cryptId) => cryptId.equals(docCryptId))
  }, [corpDocCryptIds, docCryptId])

  return { setLinkToCorp, isLinkedToCorp }
}

import { Group } from '@mantine/core'
import { useDebouncedState, useFocusTrap } from '@mantine/hooks'
import Router from 'next/router'
import React from 'react'
import { MultiSelectSearchInput } from '~/client/components/multi-select-search-input'
import { hooks } from '~/client/lib/hooks'
import type { ItemWithSearch } from '~/client/lib/hooks/search'
import {
  extractQuickAnswersValuePrefix,
  isQuickAnswerOptionValue,
  stringComparisonOptionsFilterFn,
  useDocSearchState,
  useQuickAnswerSearch,
  useQuickAnswerSearchOptions,
  useSearchOptions,
} from '~/client/lib/hooks/search'
import { useSearchFocusQueryParam } from '~/client/lib/query-params'
import { theme } from '~/client/lib/theme'
import { ZAugmentedDoc } from '~/common/schema'
import { ZAugmentedRelation } from '~/common/schema/relation'

export const SearchInput: React.FC = () => {
  const { queries, setQueries } = hooks.useSearchQueries()
  const [debouncedSelectedValues, setDebouncedSelectedValues] = useDocSearchState(queries)
  const searchFocus = useSearchFocusQueryParam()
  const focusTrapRef = useFocusTrap(searchFocus)

  const [debouncedSearch, setDebouncedSearch] = useDebouncedState('', 350)
  const fullSearchBarContents = [...debouncedSelectedValues, debouncedSearch]
  const { data, searchResults: quickAnswersQuery } = useQuickAnswerSearch({
    query: fullSearchBarContents,
    //Avoid too much layout shift in the search bar
    keepPreviousData: true,
  })
  const quickAnswersGroup = useQuickAnswerSearchOptions(data)
  const filterFn = React.useCallback((search: string, item: ItemWithSearch): boolean => {
    // Quick answers are already filtered and ordered with vector search
    if (isQuickAnswerOptionValue(item.value)) return true
    return stringComparisonOptionsFilterFn(search, item)
  }, [])
  const docSearchOptions = useSearchOptions(
    queries,
    [...ZAugmentedDoc.types, ...ZAugmentedRelation.types],
    filterFn
  )

  // navigate to search page
  React.useEffect(() => {
    // eslint-disable-next-line @typescript-eslint/no-floating-promises
    setQueries(debouncedSelectedValues)
  }, [setQueries, debouncedSelectedValues])

  return (
    <form>
      <Group maw={theme.other.widths.md} justify='space-between' wrap='nowrap'>
        <MultiSelectSearchInput
          options={{
            ...docSearchOptions,
            data: [quickAnswersGroup, ...docSearchOptions.data],
          }}
          defaultValue={queries}
          onChange={setDebouncedSelectedValues}
          onOptionSubmit={async (value) => {
            const quickAnswersValue = extractQuickAnswersValuePrefix(value)
            if (quickAnswersValue && quickAnswersValue.url) await Router.push(quickAnswersValue.url)
          }}
          placeholder='Search all documents'
          shouldSetValue={(value: string) => !isQuickAnswerOptionValue(value)}
          ref={focusTrapRef}
          onSearchChange={setDebouncedSearch}
          isFetching={quickAnswersQuery.isFetching}
        />
      </Group>
    </form>
  )
}

import { Group, Skeleton, Stack } from '@mantine/core'
import React from 'react'
import { theme } from '~/client/lib/theme'
import { Random } from '~/common/random'

const random = new Random()

export const SkeletonContent = (): React.ReactElement => {
  return (
    <Stack justify='space-between' p='16px' pl={0} align='stretch' gap='xs' h='100%' flex={1}>
      <Group align='flex-start' justify='space-between' wrap='nowrap'>
        <Stack w='100%' align='stretch' px='md' gap='xs'>
          <Group>
            {/* Doc name */}
            <Skeleton width={`${random.randomInt(50) + 25}%`} height={theme.fontSizes.lg} mb='xs' />

            {/* Badge */}
            <Skeleton width={75} height={theme.fontSizes.lg} mb='xs' ml='auto' />
          </Group>
          {/* Party name */}
          <Skeleton width={`${random.randomInt(25) + 10}%`} height={theme.fontSizes.lg} mb='xs' />
          {/* Date */}
          <Skeleton width={100} height={theme.fontSizes.lg} mb='xs' />
        </Stack>
      </Group>
    </Stack>
  )
}

import React, { useCallback } from 'react'
import type { z } from 'zod'
import { fillDeletedRelations } from '~/client/lib/deleted-relation'
import { hooks } from '~/client/lib/hooks'
import { type MkRelationsXLSXOpt, mkRelationsXLSX } from '~/client/lib/relations-xlsx'
import { enhanceRelation } from '~/common/enhance'
import { exhaustPaginated } from '~/common/exhausted-query'
import type {
  AllAugmentedRelationsMap,
  AugmentRelation,
  ZRelationTypeValues,
} from '~/common/schema/relation'

interface UseMkRelationsXLSX {
  isLoading: boolean
  mkXlsx: <RelationTypes extends ZRelationTypeValues>(
    types: RelationTypes[],
    options?: MkRelationsXLSXOpt<z.infer<AllAugmentedRelationsMap[RelationTypes]>>
  ) => Promise<Blob>
}

export const useMkRelationsXLSX = (): UseMkRelationsXLSX => {
  const [isLoading, setIsLoading] = React.useState(false)
  const fetchRelations = hooks.trpc().relations.byType.useFetchWithCorp()
  const mkXlsx = useCallback(
    async <RelationTypes extends ZRelationTypeValues>(
      types: RelationTypes[],
      options?: MkRelationsXLSXOpt<z.infer<AllAugmentedRelationsMap[RelationTypes]>>
    ) => {
      try {
        setIsLoading(true)

        const relations: AugmentRelation<{ type: RelationTypes }>[] = await exhaustPaginated(
          fetchRelations,
          {
            types,
            limit: Infinity,
            direction: 1,
            sortField: '_id',
          }
        )

        return await mkRelationsXLSX(
          fillDeletedRelations(relations.map((r) => enhanceRelation(r))),
          options
        )
      } finally {
        setIsLoading(false)
      }
    },
    [fetchRelations]
  )
  return { isLoading, mkXlsx }
}

import { z } from 'zod'
import { ZDoc } from '~/common/schema'
import { zenvCommon } from '~/common/zenv-common'

export const ZPagination = z.object({
  cursor: z.string().nullish(),
  limit: z.number().int().min(1).max(zenvCommon.NEXT_PUBLIC_PAGE_QUERY_LIMIT),
  skip: z.number().int().min(0).optional(),
  direction: z.literal(1).or(z.literal(-1)),
})

export interface ZPagination extends z.infer<typeof ZPagination> {}

export const ZDocPaginationSortField = ZDoc.pick({
  type: true,
  startDate: true,
  title: true,
})
  .extend({
    _id: z.string(),
    'party.name': z.string(),
  })
  .keyof()
export type ZDocPaginationSortField = z.infer<typeof ZDocPaginationSortField>

export const ZDocPagination = ZPagination.extend({
  sortField: ZDocPaginationSortField,
})
export interface ZDocPagination extends z.infer<typeof ZDocPagination> {}

export const ZDocSearch = ZPagination.extend({
  sortField: ZDocPaginationSortField.or(z.literal('score')),
})
export interface ZDocSearch extends z.infer<typeof ZDocSearch> {}

export const ZRelationPaginationSortField = z.enum([
  '_id',
  'startDate',
  'endDate',
  'party.name',
  'type',
])

export type ZRelationPaginationSortField = z.infer<typeof ZRelationPaginationSortField>

export const ZRelationPagination = ZPagination.extend({
  sortField: ZRelationPaginationSortField,
})

export interface ZRelationPagination extends z.infer<typeof ZRelationPagination> {}

import { LoadingOverlay } from '@mantine/core'
import Router from 'next/router'
import React from 'react'
import { EnrollMultiFactorAuth, VerifyTotpAuth } from '~/client/components/login'
import { CompulsoryPasswordReset } from '~/client/components/login/'
import { LoadingComp } from '~/client/components/util/error'
import { hooks, useCorpCryptId, useCurrentCorpAuth } from '~/client/lib/hooks'
import { Random } from '~/common/random'
import { zenvCommon } from '~/common/zenv-common'
import { AgreementModal } from './agreement-modal'
import { InitialLoader } from './initial-loader'
import { AuthenticatePhone, EnrollPhoneAuth, Login, VerifyEmailComp } from './login'
import { NotFound } from './not-found'

const AuthenticatedPage: React.FC = ({ children }) => (
  <>
    {children}
    <AgreementModal />
  </>
)

export const LoginRequired: React.FC<{ unauthenticatedComponent?: React.ReactElement }> = ({
  children,
  unauthenticatedComponent = <Login />,
}) => {
  const isLoadingFirstTime = hooks.useAuthStore((state) => state.isLoadingFirstTime)
  const user = hooks.useAuthStore((state) => state.user)
  const skipEnrollMFA = hooks.useAuthStore((state) => state.skipEnrollMFA)
  const authStatus = hooks.useAuthStatus()

  // Only show MFA prompt 20% of the time or in Cypress.
  const [shouldShowMfa] = React.useState(
    () => zenvCommon.NEXT_PUBLIC_FORCE_MFA || new Random().random() < 0.2
  )

  React.useEffect(() => {
    if (authStatus === 'AWAITING_ENROLL_MFA' && !shouldShowMfa) skipEnrollMFA()
  }, [skipEnrollMFA, authStatus, shouldShowMfa])

  if (isLoadingFirstTime) {
    return (
      <LoadingOverlay
        visible
        overlayProps={{ backgroundOpacity: 0.5 }}
        loaderProps={{ children: <InitialLoader /> }}
      />
    )
  }

  switch (authStatus) {
    case 'UNAUTHENTICATED':
      return unauthenticatedComponent
    case 'AWAITING_EMAIL_VERIFY':
      return <VerifyEmailComp email={user?.email ?? null} />
    case 'AWAITING_PHONE_VERIFY':
      return <AuthenticatePhone />
    case 'AWAITING_TOTP_VERIFY':
      return <VerifyTotpAuth />
    case 'AWAITING_ENROLL_MFA':
      if (!shouldShowMfa) return <AuthenticatedPage>{children}</AuthenticatedPage>
      return zenvCommon.NEXT_PUBLIC_ENABLE_TOTP ? <EnrollMultiFactorAuth /> : <EnrollPhoneAuth />
    case 'AWAITING_PASSWORD_RESET':
      return <CompulsoryPasswordReset />
    case 'AUTHENTICATED':
      return <AuthenticatedPage>{children}</AuthenticatedPage>
  }
}

/**
 * Requires Admin.  Assumed to be wrapped inside <CorpLayout/>
 */
export const SuperAdminRequired: React.FC = ({ children }) => {
  const userData = hooks.useCurrentUser()

  if (!userData.isLoading && !userData.data?.superAdmin) {
    return <NotFound fullPage />
  }

  return <>{children}</>
}

/**
 * Requires admin.  Assumed to be wrapped inside <CorpLayout/>
 */
export const AdminRequired: React.FC = ({ children }) => {
  const { data: auth, isLoading } = useCurrentCorpAuth()

  if (isLoading) {
    return <LoadingComp />
  }

  if (auth?.level !== 'admin') {
    return <NotFound />
  }

  return <>{children}</>
}

/**
 * Requires read-write, attorney or admin permissions. Assumed to be wrapped inside <CorpLayout/>
 */
export const ReadWriteRequired: React.FC = ({ children }) => {
  const { data: auth, isLoading } = useCurrentCorpAuth()
  const { mkCurrentCorpRoute } = useCorpCryptId()

  if (isLoading) {
    return <LoadingComp />
  }

  if (!(auth?.level === 'admin' || auth?.level === 'read-write' || auth?.level === 'attorney')) {
    // eslint-disable-next-line @typescript-eslint/no-floating-promises
    Router.push(mkCurrentCorpRoute('data-room'))
    return null
  }

  return <>{children}</>
}

export const AuthPageWrapper: React.FC = ({ children }) => {
  const isLoadingFirstTime = hooks.useAuthStore((state) => state.isLoadingFirstTime)
  const user = hooks.useAuthStore((state) => state.user)
  const authStatus = hooks.useAuthStatus()

  if (isLoadingFirstTime) {
    return (
      <LoadingOverlay
        visible
        overlayProps={{ backgroundOpacity: 0.5 }}
        loaderProps={{ children: <InitialLoader /> }}
      />
    )
  }

  switch (authStatus) {
    case 'UNAUTHENTICATED':
      return <>{children}</>
    case 'AWAITING_EMAIL_VERIFY':
      return <VerifyEmailComp email={user?.email ?? null} />
    case 'AWAITING_PHONE_VERIFY':
      return <AuthenticatePhone />
    case 'AWAITING_TOTP_VERIFY':
      return <VerifyTotpAuth />
    case 'AWAITING_ENROLL_MFA':
      return zenvCommon.NEXT_PUBLIC_ENABLE_TOTP ? <EnrollMultiFactorAuth /> : <EnrollPhoneAuth />
    case 'AWAITING_PASSWORD_RESET':
      return <CompulsoryPasswordReset />
    case 'AUTHENTICATED':
      // eslint-disable-next-line @typescript-eslint/no-floating-promises
      Router.push('/')
      return null
  }
}

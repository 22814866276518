/**
 * We handle mutation errors on the MutationCache to be able to retry them. We
 * want to retry queries that fail due to expired tokens as well, which can be
 * done in the QueryCache.  This should eliminate duplicate errors.
 *
 * This will report all TRPC errors, and also errors on our custom hooks that
 * use react-query.
 *
 * - Errors that come from TRPC, are server-side errors, which will not have
 *   meaningful stack traces in the frontend. They will be reported once by the
 *   client, and once by the server.
 * - Errors that originate on the client side, will contain the relevant stack
 *   trace, and will show sourcemaps on Sentry.
 */
import { Button, Text } from '@mantine/core'
import { randomId } from '@mantine/hooks'
import { hideNotification, showNotification } from '@mantine/notifications'
import * as Sentry from '@sentry/nextjs'
import { IconUserPlus } from '@tabler/icons-react'
import { FirebaseError } from 'firebase/app'
import Router from 'next/router'
import { ClientNotifiableError } from '~/client/components/monitoring/client-notifiable-error'
import { getCopyButton } from '~/client/components/util/error'
import { auth } from '~/client/lib/firebase'
import { WrongAuthProviderError } from '~/client/lib/wrong-auth-provider-error'
import { GenericErrorHandler } from '~/common/error-handler'
import {
  duplicateDocusignAccountOtherCorpErrorMsg,
  duplicateDocusignAccountSameCorpErrorMsg,
} from '~/common/integration/docusign'
import { isTrpcClientError } from '~/common/trpc/notifiable-error'
import { getErrorMessage } from '~/common/util'
import { zenvCommon } from '~/common/zenv-common'
import { analytics } from './analytics'
import type { RequestError } from './handlers'

const getSentryErrorDetailMsg = (id: string): string => `Sentry Error ID: ${id}`

export const getNotificationErrorMsg = (
  error: unknown,
  opt: { defaultMessage: string }
): string => {
  if (isTrpcClientError(error) && error.data?.isNotifiable) return error.message
  if (error instanceof ClientNotifiableError) return error.message

  return opt.defaultMessage
}
interface NotifyError extends Pick<RequestError, 'retry' | 'error'> {
  retryText?: string
  /** `sentryEventId` is required because we should always report to sentry
   * before notifying the user */
  sentryEventId: string
  title?: string
  getActionButton?: (notificationId: string) => JSX.Element
  /** Pass a `notificationId` to prevent showing multiple notifications for
   * the same error */
  notificationId?: string
}

export const notifyError = ({
  retry,
  retryText = 'Retry',
  error,
  sentryEventId,
  title = 'Error processing request',
  getActionButton,
  notificationId,
}: NotifyError): void => {
  const sentryErrorDetailsMsg = getSentryErrorDetailMsg(sentryEventId)
  // use randomId to prevent having the same notificationId for multiple errors
  const id = notificationId ?? randomId()
  showNotification({
    id,
    title,
    message: (
      <>
        <Text>
          {getNotificationErrorMsg(error, {
            defaultMessage: 'An unexpected error occurred',
          })}
        </Text>
        <Button.Group>
          {retry && (
            <Button
              variant='subtle'
              onClick={async () => {
                hideNotification(id)
                await retry()
              }}
            >
              {retryText}
            </Button>
          )}
          {getActionButton?.(id) ?? getCopyButton(sentryErrorDetailsMsg)}
        </Button.Group>
      </>
    ),
    color: 'danger',
    autoClose: false,
    styles: () => ({
      // As Mantine does not support moving the close button with a prop, we
      // have to move the 'X' to the upper-right corner with this css.
      root: {
        alignItems: 'start',
      },
    }),
  })
}

const isUserNotFoundError = (error: unknown) => getErrorMessage(error).includes('user-not-found')

const isTokenExpirationError = (error: unknown) =>
  getErrorMessage(error).includes('token has expired')

const handleTokenExpirationError = async (data: RequestError) => {
  await auth.forceTokenRefresh()
  await data.retry?.()
}

const containsHTML = (message: string): boolean =>
  ['<html>', '<body>', '<div>'].some((tag) => message.toLowerCase().includes(tag))

const isFirewallError = (error: unknown) => containsHTML(getErrorMessage(error))

const handleFirewallError = async (data: RequestError) => {
  const { extra } = data
  // truncate message to reduce error-reporting payload
  const message = getErrorMessage(data.error).substring(0, 999)
  const sentryEventId = Sentry.captureMessage('Firewall error', {
    extra: { ...extra, message },
  })

  const sentryErrorDetailsMsg = getSentryErrorDetailMsg(sentryEventId)

  if (extra.state.failureCount > zenvCommon.NEXT_PUBLIC_TRPC_QUERY_MAX_RETRY) {
    // using import() here to reduce build load. Without this, the build fails at the setup step on cypress.
    // See https://github.com/aerialops/aerial-app/actions/runs/4896965906/jobs/8744427524#step:4:78
    const { openFirewallErrorModal } = await import('~/client/components/modals')
    openFirewallErrorModal(sentryErrorDetailsMsg)
  }
}

interface HandleErrorOptions {
  notifyUser: boolean
  sendToAnalytics: boolean
}

const getSignupButton = (notificationId: string) => (
  <Button
    leftSection={<IconUserPlus />}
    variant='subtle'
    onClick={async () => {
      await Router.push('/signup')
      hideNotification(notificationId)
    }}
  >
    Sign up for Aerial
  </Button>
)

const isFirebaseKnownAuthError = (error: unknown): boolean => {
  if (error instanceof WrongAuthProviderError) return true
  if (error instanceof FirebaseError && error.code === 'auth/wrong-password') return true
  return false
}

const isDocusignDuplicateAccountError = (error: unknown): boolean => {
  return (
    isTrpcClientError(error) &&
    [duplicateDocusignAccountOtherCorpErrorMsg, duplicateDocusignAccountSameCorpErrorMsg].includes(
      error.message
    )
  )
}

export const handleError = (data: RequestError, options: HandleErrorOptions): void => {
  if (isFirewallError(data.error)) {
    // Can't await because this function must be sync
    // eslint-disable-next-line @typescript-eslint/no-floating-promises
    handleFirewallError(data)
    return
  }

  if (isTokenExpirationError(data.error)) {
    // eslint-disable-next-line @typescript-eslint/no-floating-promises
    handleTokenExpirationError(data)
  }

  // We want to send the error as a warning to Sentry on these, and we don't want to show notifications in that case
  if (isFirebaseKnownAuthError(data.error)) {
    // eslint-disable-next-line no-console
    console.warn(data.error)
    return
  }

  const sentryEventId = GenericErrorHandler.captureException({
    error: data.error,
    captureContext: {
      extra: { ...data.extra, fullErrorMessage: getErrorMessage(data.error) },
    },
  })
  data.addSentryIdToErrorState(sentryEventId)

  // Error is handled in the integration card, so we don't want to show a notification as well
  const shouldShowNotification = !isDocusignDuplicateAccountError(data.error)

  if (options.notifyUser && shouldShowNotification) {
    if (isUserNotFoundError(data.error)) {
      const notificationData = {
        ...data,
        sentryEventId,
        title: 'Login Error',
        error: new ClientNotifiableError("We didn't recognize this email address"),
        retry: undefined,
        getActionButton: getSignupButton,
      }
      notifyError(notificationData)
    } else notifyError({ ...data, sentryEventId })
  }

  if (options.sendToAnalytics) {
    analytics.track({
      type: data.type,
      extra: data.extra,
    })
  }
}

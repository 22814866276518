import { ScrollArea, Stack, Tabs } from '@mantine/core'
import { useRouter } from 'next/router'
import React from 'react'
import {
  useDocDetailState,
  useDocDetailViewStore,
  useFormSaveMethods,
} from '~/client/components/doc-detail/state'
import { ExtractTab } from '~/client/components/doc-detail/term/extract-tab'
import { RedFlagsTab } from '~/client/components/doc-detail/term/red-flags-tab'
import {
  RelationsTab,
  usePreloadRelationsTab,
} from '~/client/components/doc-detail/term/relations-tab'
import { RedFlagStatusIcon } from '~/client/components/red-flags'
import { InvestorRedFlagExplanationHoverCard } from '~/client/components/util/red-flags'
import { hooks, useCurrentCorpAuth } from '~/client/lib/hooks'
import { PropertiesTab } from './properties'

export const Terms: React.FC = () => {
  const { data: auth } = useCurrentCorpAuth()
  const { query } = useRouter()
  const form = useDocDetailState((state) => state.form)
  const docCryptId = useDocDetailState((state) => state.docCryptId)
  const isInvestor = auth?.level === 'investor'
  const redFlagsQueryResult = hooks.trpc().redFlags.get.byPrimaryIds.useQueryWithCorp(
    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
    { primaryCryptIds: [docCryptId!] },
    { enabled: !!docCryptId && !!auth && !isInvestor }
  )
  const promptSave = useDocDetailState((state) => state.promptSave)
  const { onSubmit, save } = useFormSaveMethods()
  const closeDocDetail = useDocDetailViewStore((state) => state.closeModal)
  const isFullScreen = useDocDetailViewStore(
    (state) => state.docDetailViewState.state === 'fullscreen'
  )
  const modalOpened = useDocDetailViewStore((state) => state.docDetailViewState.state === 'modal')
  // We don't use `useNavigationTabs` here cause we just want to use the tab
  // value from the url as the initial value
  const [activeTab, setActiveTab] = React.useState<string | null>(
    typeof query.tab === 'string' && isFullScreen ? query.tab : 'properties'
  )
  const cryptIdDefined = !!docCryptId
  // Since keepMounted={false}, the RelationsTab component will only load when it's active
  // Preload the first query to make the UX a bit faster
  usePreloadRelationsTab(docCryptId)

  const activeRedFlags = redFlagsQueryResult.data?.filter((redFlag) => !redFlag.dismissed) ?? []

  const onFormSubmit = React.useCallback(async () => {
    const value = await promptSave()
    if (value) onSubmit()
  }, [promptSave, onSubmit])

  const handleRelationTabClick = React.useCallback(async () => {
    if (!form.isDirty()) return
    if (form.validate().hasErrors) return setActiveTab('properties')
    const confirmed = await promptSave()
    if (confirmed) await save(form.values)
    else setActiveTab('properties')
  }, [form, promptSave, save])

  return (
    <Stack
      w={365}
      mih='100%'
      h='100%'
      justify='space-between'
      mr='xs'
      data-testid='doc-detail-terms'
    >
      <Tabs
        value={activeTab}
        onChange={setActiveTab}
        h='100%'
        display='flex'
        style={{
          flexDirection: 'column',
        }}
        // Dismount tabs on change to prevent overriding `display: none` with
        // our `display: flex` panels, which causes tabs to not work properly.
        keepMounted={false}
      >
        <Tabs.List grow>
          <Tabs.Tab value='properties' data-testid='properties-tab' p='0'>
            Properties
          </Tabs.Tab>
          <Tabs.Tab
            value='relations'
            disabled={!cryptIdDefined}
            onClick={handleRelationTabClick}
            data-testid='relations-tab'
            p='0'
          >
            Relations
          </Tabs.Tab>
          <Tabs.Tab value='extract' data-testid='extract-tab' p='0' disabled={isInvestor}>
            Extract
          </Tabs.Tab>
          <Tabs.Tab
            p='0'
            value='red-flags'
            data-testid='red-flags-tab'
            styles={{ tabSection: { margin: 0 }, tabLabel: { paddingRight: 5 } }}
            disabled={isInvestor || !cryptIdDefined}
            rightSection={
              isInvestor ? (
                <InvestorRedFlagExplanationHoverCard badgeSize='sm' />
              ) : (
                <RedFlagStatusIcon
                  state={activeRedFlags.length > 0 ? 'missing' : 'present'}
                  size='sm'
                />
              )
            }
          >
            Red Flags
          </Tabs.Tab>
        </Tabs.List>
        <Tabs.Panel value='properties' pt='md'>
          <form onSubmit={form.onSubmit(onFormSubmit)}>
            <PropertiesTab onCancel={modalOpened ? () => closeDocDetail() : undefined} />
          </form>
        </Tabs.Panel>
        <Tabs.Panel
          value='relations'
          pt='md'
          mih={0}
          display='flex'
          style={{ flexDirection: 'column' }}
        >
          <ScrollArea type='auto'>
            {cryptIdDefined && <RelationsTab docCryptId={docCryptId} />}
          </ScrollArea>
        </Tabs.Panel>
        <Tabs.Panel value='extract' pt='md' h='100%'>
          <ExtractTab />
        </Tabs.Panel>
        <Tabs.Panel keepMounted value='red-flags' h='100%'>
          {cryptIdDefined && <RedFlagsTab redFlagsQueryResult={redFlagsQueryResult} />}
        </Tabs.Panel>
      </Tabs>
    </Stack>
  )
}

import type { z } from 'zod'
import {
  ZAugmentedDocusignIntegration,
  ZAugmentedDocusignSource,
} from '~/common/schema/integration/docusign'

export const ZAugmentedIntegration = ZAugmentedDocusignIntegration
export type ZAugmentedIntegration = z.infer<typeof ZAugmentedIntegration>

export const ZAugmentedSource = ZAugmentedDocusignSource
export type ZAugmentedSource = z.infer<typeof ZAugmentedSource>
